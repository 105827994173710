import { useState, useCallback } from 'react';
import useHttp from "../hooks/use-http";

const AddFactor = (props) => {
  const { type, dictionary, onAddFactor, readOnly } = props;
  const { isLoading, error, sendRequest } = useHttp();
  const [showDialog, setShowDialog] = useState('');
  const [newFactor, setNewFactor] = useState('');
  const [dialogDisabled, setDialogDisabled] = useState(false);

  const showDialogHandler = () => {
    setShowDialog('mdc-dialog--opening');
    setTimeout(() => {
      setShowDialog('mdc-dialog--opening mdc-dialog--open');
    }, 10);
    setTimeout(() => {
      setShowDialog('mdc-dialog--open');
    }, 140);
  };

  const hideDialogHandler = () => {
    setShowDialog('mdc-dialog--closing mdc-dialog--open');
    setTimeout(() => {
      setShowDialog('');
    }, 70);
  };

  const inputFactorHandler = (e) => {
    setNewFactor(e.target.value)
  };

  // add new factor
  const addFactorHandler = useCallback((factorData) => {
    if (newFactor && newFactor.length > 2) {
      setDialogDisabled(true);
      const data = new FormData();
      data.append("factor", JSON.stringify({
        type: type,
        name: newFactor
      }));
      
      sendRequest({
        url: window.location.pathname,
        method: 'POST',
        body: data
      }, (response) => {
        console.log(response);
        if (response.id) {
          onAddFactor(response.id);
        }
        setDialogDisabled(false);
        hideDialogHandler();
      });
    }
  }, [newFactor, sendRequest]);
  
  return (<div>
  <button disabled={readOnly} className="matter-button-text with-leading-icon" onClick={showDialogHandler}><span className="material-icons">add</span>{dictionary['add-own']}</button>
  <div className={`mdc-dialog dialog-fullscreen ${showDialog}`}>
      <div className='mdc-dialog__container'>
        <div className="mdc-dialog__surface">
          <div className="mdc-dialog__content">
            <h3>{dictionary['add-factor']}</h3>
            <div className="x-input w-100 w-factor-l">
              <input type="text" placeholder={dictionary['enter-factor']} value={newFactor} onInput={inputFactorHandler} />
            </div>
          </div>
          <div className="mdc-dialog__actions">
            <button onClick={hideDialogHandler} className="matter-button-text mr2">{dictionary['cancel']}</button>
            <button disabled={dialogDisabled} onClick={addFactorHandler} className="matter-button-text btn-primary">{dictionary['add']}</button>
          </div>
        </div>
      </div>
      <div className="mdc-dialog__scrim"></div>
    </div>
  </div>);
}

export default AddFactor;