const FactorChart = (props) => {
  const { highlight, type, symbol, name, legend, legendX, legendY, x, y, minX, maxX, minY, maxY, ceilX, ceilY, counter } = props;

  const left = 100 * ((x - minX) / (maxX - minX)) + '%';
  const bottom = 100 * ((y - minY) / (maxY - minY)) + '%';

  const progressX = (x / ceilX) * 100;
  const progressY = (y / ceilY) * 100;

  const style = {
    left: left, 
    bottom: bottom
  };

  if (counter === 2) {
    style.marginLeft = '-12px';
  }
  if (counter === 3) {
    style.marginBottom = '-12px';
    style.marginLeft = '-18px';
  }
  if (counter === 4) {
    style.marginBottom = '-32px';
    style.marginLeft = '-18px';
  }
  
  return (<div className={`chart-xy ${type}`} style={style}>
    <div className={`chart-point flex items-center justify-center tip-menu ${highlight ? "pulse" : ""}`}>
      <span className="fw5">{symbol}</span>
      <nav>
        <div className="pa3">
          <h3>{name}</h3>
          <div className="onsurface-light f6 pl2 pb1">{legend}</div>
          <label className={`factor-progress with-tail ${type ? type : ''}`}>
            <span className="indicator" style={{width: progressX + '%'}}></span>
            <span className="label">{legendX}</span>
            <strong className="tail">{ x }</strong>
          </label>
          <label className={`factor-progress with-tail ${type ? type : ''}`}>
            <span className="indicator" style={{width: progressY + '%'}}></span>
            <span className="label">{legendY}</span>
            <strong className="tail">{ y }</strong>
          </label>
        </div>
      </nav>
    </div>
  </div>);
}

export default FactorChart;