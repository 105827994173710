import { useContext } from "react";
import PageContext from "../context/page-context";

const LangSwitch = () => {
  const { lang, onSetLang } = useContext(PageContext);

  const changeLang = (event) => {
    const newLang = event.target.dataset.lang;
    onSetLang(newLang);
  }
  
  return (<div className="icon-btn btn-tonal btn-l tip-menu">
  <span className="ttu">{lang}</span>
  <nav className="lang-switch pa2">
    { lang != 'pl' && <div className="pa2" onClick={changeLang} data-lang="pl">PL</div> }
    { lang != 'en' && <div className="pa2" onClick={changeLang} data-lang="en">EN</div> }
    { lang != 'no' && <div className="pa2" onClick={changeLang} data-lang="no">NO</div> }
  </nav>
</div>);
}

export default LangSwitch;