import { useEffect, useState, useContext } from "react";
import FactorProgress from "./FactorProgress";
import PageContext from "../context/page-context";

const FactorsRateSummary = (props) => {
  const { factors, readOnly, tip, compare_attr, dictionary } = props;
  const [mounted, setMounted] = useState(false);
  const { unfold, onSetUnfold } = useContext(PageContext);

  let items = []; 
  
  if (factors !== undefined) {
    if (compare_attr === 'predictability') {
      items = factors.map((el) => {
        // return {...el, compare_attr: (el.compare_attr) ? 8 - el.compare_attr : 0 };
        return {...el, compare_attr: (el.compare_attr !== undefined && el.compare_attr > 0) ? Math.round((8 - el.compare_attr) * 10) / 10 : 0 };
      });
    } else {
      items = factors;
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    }, 140);
  });
  

  // progress
  const maxCompare_attr = Math.max(...items.map(o => o.compare_attr));
  items.sort((a, b) => (a.compare_attr > b.compare_attr) ? -1 : 1);

  // unfold
  const onUnfoldHandler = () => {
    onSetUnfold(!unfold);
  }

  return (<div className="card pt5 pt6-l pb5 pb4-l ph3 ph7-l">
    <div className={`flex justify-end animate-enter-up ${mounted ? 'animated' : ''}`}>
      <div className="dib relative w-tail mr075">
        <div className="tooltip-wrapper">
          <div className="tooltip">{ tip }</div>
        </div>
      </div>
    </div>
    <div className={`${unfold ? "" : "scroll h-scroll"}`}>
      {items.map(item => <FactorProgress key={item.id} label={item.name} id={item.id} type={item.type} symbol={item.symbol} compare_attr={item.compare_attr} maxCompare_attr={maxCompare_attr} readOnly={readOnly} />)}
    </div>
    <div className="mt3 flex items-center justify-end">
      <div className='dn db-l'>
        <button onClick={onUnfoldHandler} className="matter-button-text with-leading-icon"><span className="material-icons">unfold_more</span>{unfold ? dictionary['fold'] : dictionary['unfold']}</button>
      </div>
    </div>
  </div>);
}

export default FactorsRateSummary;