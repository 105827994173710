

const FactorCheckbox = (props) => {
  // const checked = props.state && props.state[props.compute_attr] === 1;
  const checked = props.state !== undefined && props.state[props.compute_attr] !== undefined && props.state[props.compute_attr] === 1;

  const progress = (props.maxCompare_attr && props.compare_attr && props.maxCompare_attr > 0) ? ((props.compare_attr / props.maxCompare_attr) * 100) : 0;
  const disabled = props.readOnly || false;

  const updateFactorHandler = () => {
    // props.onUpdate(props.value);
    props.onUpdate({ id: props.value, checked: !checked });
  }
  
  const indicatorStyle = (progress) ? { width: progress + '%' } : {};
  
  // const symbol = (props.state.hasOwnProperty('symbol')) ? props.state.symbol : '';
  let symbol;
  if (props.state !== undefined) {
    symbol = props.state.symbol ?? '';
  }

  return (<label className={`factor-checkbox ${(props.tail) ? "with-tail" : ""} ${(props.progress) ? "with-progress" : ""}`}>
    <input type="checkbox" checked={checked} onChange={updateFactorHandler} disabled={disabled} />
    <span className="indicator" style={indicatorStyle}></span>
    <span className="label">{symbol && <strong>{symbol} </strong> }{props.label}</span>
    {props.tail && <strong className="tail">{props.compare_attr}</strong>}
  </label>);
}

export default FactorCheckbox;