import { useState, useEffect } from 'react';
import FactorCheckboxDual from './FactorCheckboxDual';

const FactorsCheckboxDual = (props) => {
  const { type, factors, state, compute_attr, dictionary, onUpdate, readOnly } = props;
  const [mounted, setMounted] = useState(false);
  
  const updateStateParameterHandler = data => {
    const { id } = data;

    const factorExistIndex = state.findIndex(el => el.id === id); // some
    let newState = [];
    
    if (factorExistIndex !== -1) {
      if (compute_attr === 'indications') {
        newState = state.filter((obj) => obj.id !== id); // remove factor
      } else {
        newState = state;
        const oldAttr = newState[factorExistIndex][compute_attr];
        if (oldAttr && oldAttr === 1) {
          delete(newState[factorExistIndex][compute_attr]);
        } else {
          newState[factorExistIndex][compute_attr] = 1;
        }
      }
    } else {
      newState = [].concat(state, [{ id: id, [compute_attr]: 1 }]);
    }

    onUpdate({
      type: type,
      state: newState
    });
  }

  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    }, 140);
  });
  
  return (<div className={"card pt6 pb6 ph3x ph7-lx ph-card " + type}>
    <div className="half-cover z-1"></div>
    <div className='flex items-center justify-center dual-arrows'>
      <div className={`dual-arrow-left mr3 animate-enter-left ${mounted ? 'animated' : ''}`}>{dictionary['uncertainty']}</div>
      <div className={`dual-arrow ml3 animate-enter-right ${mounted ? 'animated' : ''}`}>{dictionary['importance']}</div>
    </div>
    <div className="dual-scale flex mb3 fw5 onsurface-lighter">
      <div>7</div><div>6</div><div>5</div><div>4</div><div>3</div><div>2</div><div>1</div><div>1</div><div>2</div><div>3</div><div>4</div><div>5</div><div>6</div><div>7</div>
    </div>
    <div className="scroll mh-scroll relative z-2">
      {factors && factors.map(item => <FactorCheckboxDual key={item.id} type={item.type} symbol={item.symbol} label={item.name} value={item.id} compute_attr={compute_attr} state={(state) ? state.find(el => el.id === item.id) : {}} onUpdate={updateStateParameterHandler} compare_attr={item.compare_attr2} compare_attr2={item.compare_attr} readOnly={readOnly} />)}
    </div>
  </div>);
}

export default FactorsCheckboxDual;