import { useEffect, useState, useContext } from "react";
import FactorRate from "./FactorRate";
import PageContext from "../context/page-context";

const FactorsRate = (props) => {
  const { factors, state, type, compute_attr, readOnly, tipLo, tipHi, onUpdate, dictionary } = props;
  const [mounted, setMounted] = useState(false);
  const { unfold, onSetUnfold } = useContext(PageContext);
  
  const updateStateParameterHandler = data => {
    const { id, val } = data;

    const factorExistIndex = state.findIndex(el => el.id === id); 
    let newState = [];

    if (factorExistIndex !== -1) {
      newState = state;
      // const oldAttr = newState[factorExistIndex][compute_attr];
      newState[factorExistIndex][compute_attr] = val;
    } else {
      newState = [].concat(state, [{ id: id, [compute_attr]: val }]);
    }

    onUpdate({
      type: type,
      state: newState
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    }, 140);
  });

  // unfold
  const onUnfoldHandler = () => {
    onSetUnfold(!unfold);
  }
  
  return (<div className="card pt5 pt6-l pb5 pb4-l ph3 ph7-l">
          
    <div className="mr3-lx">
      <div className={`flex justify-between animate-enter-up ${mounted ? 'animated' : ''}`}>
        <div className="db w-14 flex justify-end">
          <div className="relative db w1 mr4">
            <div className="tooltip-wrapper">
              <div className="tooltip tip-wide tc">{ tipLo }</div>
            </div>
          </div>
        </div>
        <div className="db w-14 flex justify-end">
          <div className="relative db w1 mr4">
            <div className="tooltip-wrapper">
              <div className="tooltip tip-wide tc">{ tipHi }</div>
            </div>
          </div>
        </div>
      </div>

      <ul className="list flex flex-wrap ma0 pa0 f2 fw5 mb3 rating-scale">
        <li className="db w-14"><div className="w-80 tr">1</div></li>
        <li className="db w-14"><div className="w-80 tr">2</div></li>
        <li className="db w-14"><div className="w-80 tr">3</div></li>
        <li className="db w-14"><div className="w-80 tr">4</div></li>
        <li className="db w-14"><div className="w-80 tr">5</div></li>
        <li className="db w-14"><div className="w-80 tr">6</div></li>
        <li className="db w-14"><div className="w-80 tr">7</div></li>
      </ul>
    </div>

    <div className={`${unfold ? "" : "scroll h-scroll"}`}>
      {factors !== undefined && factors.map(item => <FactorRate key={item.id} label={item.name} name={'rating['+item.id+']'} id={item.id} type={item.type} symbol={item.symbol} compute_attr={compute_attr} state={(state) ? state.find(el => el.id === item.id) : {}} onUpdate={updateStateParameterHandler} readOnly={readOnly} />)}
    </div>

    <div className="mt3 flex items-center justify-end">
      <div className='dn db-l'>
        <button onClick={onUnfoldHandler} className="matter-button-text with-leading-icon"><span className="material-icons">unfold_more</span>{unfold ? dictionary['fold'] : dictionary['unfold']}</button>
      </div>
    </div>
  </div>)
}

export default FactorsRate;
