import {useDraggable} from '@dnd-kit/core';
import { CSS } from "@dnd-kit/utilities";

const Draggable = (props) => {
  const {attributes, listeners, setNodeRef, transform, transition} = useDraggable({
    id: props.id,
    disabled: (props.readOnly === true) ? true : false
  });

  /* const style = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    transition,
    position: 'fixed',
    cursor: 'grabbing',
    maxWidth: '504px'
  } : undefined; */

  const style = transform ? {
    translate: CSS.Translate.toString(transform)
  } : undefined;
  
  /* const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }; */

  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes} className="dragg">
      {props.children}
    </div>
  );
};

export default Draggable;

// https://github.com/WawanC/react-dnd-kit-basic/blob/main/src/components/fruit-draggable/FruitDraggable.tsx