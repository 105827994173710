import { useEffect, useContext, useState } from "react";
import PageContext from "../context/page-context";
import ScenarioNameCard from "./ScenarioNameCard";
import ScenariosExample from "./ScenariosExample";

const ScenariosNames = (props) => {
  const { current, ord, state, factors, dictionary, type, onUpdate, onValid, project, readOnly } = props;
  const [command, setCommand] = useState(dictionary.command);
  // const [factorsSelected, setFactorsSelected] = useState(factors[type].filter((el) => el.compare_attr === 1));
  const { onHelp } = useContext(PageContext);

  const scenariosIds = [1, 2, 3, 4];

  // state
  const items = state.scenarios ?? [];

  // selected factors
  const factorsSelected = factors[type];

  // on factors change
  /* useEffect(() => {
    setFactorsSelected(factors[type].filter((el) => el.compare_attr === 1));
  }, [factors, type]); */

  // command
  useEffect(() => {
    let cmd = dictionary.command.replace('{time_horizon}', project.time_horizon).replace('{area}', project.area);
    if (factorsSelected[0]) { cmd = cmd.replace('{factor1}', factorsSelected[0].name); }
    if (factorsSelected[1]) { cmd = cmd.replace('{factor2}', factorsSelected[1].name); }
    setCommand(cmd);
  }, [factorsSelected, dictionary.command, project.time_horizon, project.area]);
  
  // page validation
  useEffect(() => {
    let valid;
    if (readOnly === true && current < ord) {
      valid = false; // uczen na ekranie grupowym czeka na zatwierdzenie nauczyciela
    } else if (current >= ord) {
      valid = true; // skip - krok juz zapisany
    } else {
      const setted = (items) ? items.filter((obj) => obj.name && obj.name.length > 0).length : 0;
      valid = (setted === 4);
    }
    onValid(valid);
  });

  // update page state
  const updateCurrentStateHandler = (data) => {
    const factorExistIndex = items.findIndex(el => el.id === data.id); // some
    let newState;
    if (factorExistIndex !== -1) {
      newState = items;
      newState[factorExistIndex] = data.state;
    } else {
      newState = [].concat(items, [data.state]);
    }
    
    onUpdate({
      type: 'scenarios',
      state: newState
    });
  };

  // page help modal
  const  help = () => {
    onHelp({ html: dictionary.help });
  };
  
  return (<main className="main-section flex w-100 items-center justify-center">
    <div className="w-100 flex flex-column justify-center items-center">
      <section className="step wide w-100 w-80-l ph3 ph0-l step-error">
        <div className="command command-wide w-90-l flex center mb4 mb4-l">
          <div className="f3" dangerouslySetInnerHTML={{ __html: command }}></div>
          <div className="pl4 flex flex-column">
            <button className="icon-btn btn-primary btn-m" onClick={help}>
              <span className="material-icons">question_mark</span>
            </button>
            <ScenariosExample dictionary={dictionary} />
          </div>
        </div>
      </section>
      <section className="scenarios w-100 w-90-l max-wide ph3 ph0-l relative">
        <div className="flex justify-center mb2">
          <div className={`label-y center ${factorsSelected[1].type}`}>
            <div className="onsurface-light">{dictionary['high-level']}</div> 
            {factorsSelected[1] && <strong>{factorsSelected[1].name}</strong>}
          </div>
        </div>

        <div className="relative mh3">
          <div className="max-step center relative">
            <div className="grid grid-cols-2 gap-8">
              {scenariosIds.map(item => <ScenarioNameCard key={item} id={item} state={items.find(el => el && el.id === item) ?? {}} pholder={dictionary['s-name']} onUpdate={updateCurrentStateHandler} readOnly={readOnly} />)}
              <div className="scenario-x-axis flex items-center">
                <div className="x-arrows"></div>
              </div>
              <div className="scenario-y-axis flex justify-center">
                <div className="y-arrows"></div>
              </div>
            </div>
          </div>
        </div>

        <div className={`label-x label-left center ${factorsSelected[0].type}`}>
          <div className="onsurface-light">{dictionary['low-level']}</div> 
          {factorsSelected[0] && <strong>{factorsSelected[0].name}</strong>}
        </div>
        <div className={`label-x label-right center ${factorsSelected[0].type}`}>
          <div className="onsurface-light">{dictionary['high-level']}</div> 
          {factorsSelected[0] && <strong>{factorsSelected[0].name}</strong>}
        </div>

        <div className="flex justify-center mt2">
          <div className={`label-y center ${factorsSelected[1].type}`}>
            <div className="onsurface-light">{dictionary['low-level']}</div> 
            {factorsSelected[1] && <strong>{factorsSelected[1].name}</strong>}
          </div>
        </div>

      </section>
    </div>
  </main>);
}
// <img className="db w-100" src="/images/arrow_h.svg" alt="" />
// <img className="db h-100" src="/images/arrow_v.svg" alt="" />
export default ScenariosNames;