import FactorProgress from "./FactorProgress";

const FactorsProgress = (props) => {
  const { type, factors, title, signet, signetPosition } = props;
  
  return (<div className={"card pt5 pb4 ph3 ph5-l h-100 " + type }>
    <div className={"card-signet dn flex-l " + ((signetPosition === 'right') ? 'card-signet-right' : '')}><span className="fw6 f1 ff-title">{signet}</span></div>
    <h3 className="f3 fw6 mb3 ph2">{title}</h3>
    <div>
      {factors !== undefined && factors.map(item => <FactorProgress key={item.id} symbol={item.symbol ?? ''} label={item.name} value={item.id}  />)}
    </div>
  </div>)
}

export default FactorsProgress;