import FactorCheckbox from './FactorCheckbox';

const FactorsCheckboxGroup = (props) => {
  const { factors, state, compute_attr, type, title, tip, readOnly } = props;

  // progress
  const maxCompare_attr = Math.max(...factors.map(o => o.compare_attr));
  factors.sort((a, b) => (a.compare_attr > b.compare_attr) ? -1 : 1);
  
  const updateStateParameterHandler = data => {
    const { id } = data;

    const factorExistIndex = state.findIndex(el => el.id === id); // some
    let newState = [];
    
    if (factorExistIndex !== -1) {
      if (compute_attr === 'indications') {
        newState = state.filter((obj) => obj.id !== id); // remove factor
      } else {
        newState = state;
        const oldAttr = newState[factorExistIndex][compute_attr];
        if (oldAttr && oldAttr === 1) {
          delete(newState[factorExistIndex][compute_attr]);
        } else {
          newState[factorExistIndex][compute_attr] = 1;
        }
      }
    } else {
      newState = [].concat(state, [{ id: id, [compute_attr]: 1 }]);
    }

    // add symbols
    if (compute_attr === 'selected') {
      const letter = type.charAt(0).toUpperCase();
      let counter = 1;
      for (let i = 0; i < newState.length; i++) {
        if (newState[i].selected === 1) {
          newState[i].symbol = letter + counter;
          counter++;
        } else {
          delete(newState[i].symbol);
        }
      }
    }
    
    props.onUpdate({
      type: props.type,
      state: newState
    });
  }

  return (<div className={"card pt5 pt6-l pb4 ph3x ph7-lx ph-card " + props.type}>
    <h3 className="f2 fw6 mt0 mb3 ph2">{title}</h3>
    <div className="flex justify-end">
      <div className="dib relative w-tail">
        <div className="tooltip-wrapper">
          <div className="tooltip">{tip}</div>
        </div>
      </div>
    </div>
    <div className="scroll h-scroll">
      {factors && factors.map(item => <FactorCheckbox key={item.id} label={item.name} value={item.id} compute_attr={compute_attr} state={(state) ? state.find(el => el.id === item.id) : {}} onUpdate={updateStateParameterHandler} tail="1" progress="1" compare_attr={item.compare_attr} maxCompare_attr={maxCompare_attr} readOnly={readOnly} />)}
    </div>
  </div>);
}

export default FactorsCheckboxGroup;