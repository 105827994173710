import { useEffect, useState, forwardRef } from "react";
import FactorChart from "./FactorChart";

const Chart = forwardRef((props, ref) => {
  const { current, ord, factors, type, project, dictionary, onValid, readOnly } = props;
  const [mounted, setMounted] = useState(false);
  const [zoom, setZoom] = useState(false);
  
  let items = factors[type] ?? [];

  // check duplicates of x,y
  const dupls = [];
  items = items.map((el) => {
    let xySum = el.compare_attr * 10 + el.compare_attr2;
    dupls[xySum] = (dupls[xySum]) ? dupls[xySum] + 1 : 1;
    return {...el, counter: dupls[xySum] }
  });

  const x = 'compare_attr';
  const y = 'compare_attr2';
  
  /*
  const minX = 1; // items.reduce((min, p) => p[x] < min ? p[x] : min, items[0][x]);
  const minY = 1; // items.reduce((min, p) => p[y] < min ? p[y] : min, items[0][y]);
  const maxX = 7; // items.reduce((max, p) => p[x] > max ? p[x] : max, items[0][x]);
  const maxY = 7; // items.reduce((max, p) => p[y] > max ? p[y] : max, items[0][y]);
  */

  const minX = (zoom) ? items.reduce((min, p) => p[x] < min ? p[x] : min, items[0][x]) : 1;
  const minY = (zoom) ? items.reduce((min, p) => p[y] < min ? p[y] : min, items[0][y]) : 1;
  const maxX = (zoom) ? items.reduce((max, p) => p[x] > max ? p[x] : max, items[0][x]) : 7;
  const maxY = (zoom) ? items.reduce((max, p) => p[y] > max ? p[y] : max, items[0][y]) : 7;


  // x axis
  let minXaxis = Math.floor(minX);
  if (minXaxis === minX) { minXaxis -= 0.5; }
  let maxXaxis = Math.ceil(maxX);
  if (maxXaxis === maxX) { maxXaxis += 0.5; }

  // y axis
  let minYaxis = Math.floor(minY);
  if (minYaxis === minY) { minYaxis -= 0.5; }
  let maxYaxis = Math.floor(maxY);
  if (maxYaxis === maxY) { maxYaxis += 0.5; }

  // x grid
  const xGridCount = (maxXaxis - minXaxis) / 0.5;
  const xGridPercent = 100 / xGridCount;
  const xGrids = [];
  for (let i = 0; i <= xGridCount; i++) {
    xGrids[i] = { lab: (minXaxis + (i * 0.5)), pos: xGridPercent * i };
  }

  // y grid
  const yGridCount = (maxYaxis - minYaxis) / 0.5;
  const yGridPercent = 100 / yGridCount;
  const yGrids = [];
  for (let i = 0; i <= yGridCount; i++) {
    yGrids[i] = { lab: (minYaxis + (i * 0.5)), pos: yGridPercent * i };
  }

  // y dash (x0)
  const itemsCount = items.length;
  const xSum = items.reduce((acc, el) => { return acc + el[x]; }, 0);
  const yDash = (xSum > 0) ? xSum / itemsCount : 1;
  const yDashLeft = 100 * ((yDash - minXaxis) / (maxXaxis - minXaxis));

  // x dash (y0)
  const ySum = items.reduce((acc, el) => { return acc + el[y]; }, 0);
  const xDash = (ySum > 0) ? ySum / itemsCount : 1; 
  const xDashBottom = 100 * ((xDash - minYaxis) / (maxYaxis - minYaxis));

  // y tip
  const tipYhi = dictionary['tip-y-hi'].replace('{time_horizon}', project.time_horizon);
  const tipYlo = dictionary['tip-y-lo'].replace('{time_horizon}', project.time_horizon);
  // command
  const command = dictionary.command.replace('{area}', project.area).replace('{time_horizon}', project.time_horizon);
  // info
  const info = (dictionary.info) ? dictionary.info.replace('{area}', project.area).replace('{scenario_name}', project.scenario_name) : '';
  // xLabel
  const xLabel = (dictionary['x-label']) ? dictionary['x-label'].replace('{time_horizon}', project.time_horizon) : '';
  
  // zoom
  const zoomHandler = () => {
    setZoom(!zoom);
  };
  const zoomIcon = (zoom) ? 'zoom_out_map' : 'zoom_in_map';

  // valid
  useEffect((data) => {
    let valid;
    if (readOnly === true && current < ord) {
      valid = false; // uczen na ekranie grupowym czeka na zatwierdzenie nauczyciela
    } else if (current >= ord) {
      valid = true; // skip - krok juz zapisany
    } else {
      valid = true; // walidacja
    }
    onValid(valid);
  });

  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    }, 140);
  });

  return (<main className="main-section flex w-100 items-center justify-center">
    <section className="step widex w-100 w-80-l ph3 ph0-l">
      <div className="command w-80-l flex center mb5">
        <div className="f3" dangerouslySetInnerHTML={{ __html: command }}></div>
      </div>
      <div className="relative">
        <div className={`chart-info flex animate-enter-up ${mounted ? 'animated' : ''}`}>
          <div><span className="material-icons mr2">info_outline</span></div>
          <div dangerouslySetInnerHTML={{ __html: info }}></div>
        </div>

        <div ref={ref} className="pl5 pb5">
          <div className="card pv7 ph3 ph7-l chart relative">
            <div className="chart-content relative">
              {xGrids.map((el, i) => <div key={i} style={{ left: el.pos + '%'}} className="x-grid"><div className="x-label"><div>{(el.lab <= 7 && el.lab >= 1) && el.lab}</div></div></div>)}
              {yGrids.map((el, i) => <div key={i} style={{ bottom: el.pos + '%'}} className="y-grid"><div className="y-label"><div>{(el.lab <= 7 && el.lab >= 1) && el.lab}</div></div></div>)}
              <div className="x-dash" style={{ bottom: xDashBottom + '%'}} >
                <div className="x-dash-tip" style={{ bottom: xDashBottom + '%'}}>{xLabel}</div>
              </div>
              <div className="y-dash" style={{ left: yDashLeft + '%'}}>
                <div className="y-dash-tip" style={{ left: yDashLeft + '%'}}>{dictionary['y-label']}</div>
              </div>
              <div className="x-axis"></div>
              <div className="y-axis"></div>
              <div className="x-arrow"></div>
              <div className="y-arrow"></div>
              {items.map(item => <FactorChart key={item.id} highlight={item.compare_attr > yDash && item.compare_attr2 > xDash} type={item.type} symbol={item.symbol} name={item.name} legend={dictionary['tip-legend']} legendX={dictionary['current-year']} legendY={project.time_horizon + ' ' + dictionary['year']} x={item[x]} y={item[y]} minX={minXaxis} maxX={maxXaxis} minY={minYaxis} maxY={maxYaxis} ceilX="7" ceilY="7" counter={item.counter} />)}
            </div>
            <div className="top-tip chart-y-hi" dangerouslySetInnerHTML={{ __html: tipYhi }}></div>
            <div className="top-tip chart-y-lo" dangerouslySetInnerHTML={{ __html: tipYlo }}></div>
            <div className="bottom-tip chart-x-lo" dangerouslySetInnerHTML={{ __html: dictionary['tip-x-lo'] }}></div>
            <div className="bottom-tip chart-x-hi" dangerouslySetInnerHTML={{ __html: dictionary['tip-x-hi'] }}></div>
            <button className="icon-btn btn-primary btn-m chart-zoom" onClick={zoomHandler}>
              <span className="material-icons">{zoomIcon}</span>
            </button>
          </div>
        </div>
        
      </div>
      
    </section>
  </main>);
});

export default Chart;

/* <div className="flex justify-between ph3 chart-label-x">
        <div className="bottom-tip" dangerouslySetInnerHTML={{ __html: dictionary['tip-x-lo'] }}></div>
        <div className="bottom-tip" dangerouslySetInnerHTML={{ __html: dictionary['tip-x-hi'] }}></div>
      </div> */