// import { Link } from "react-router-dom";
import { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import useHttp from "../hooks/use-http";

const ProjectFooter = (props) => {
  const { showUnlock, dictionary, lastOrd, ord, timeLeft, valid, taskProgress } = props;
  const [showDialog, setShowDialog] = useState('');
  const [dialogDisabled, setDialogDisabled] = useState(false);
  const [counter, setCounter] = useState(timeLeft);
  const [isDisabled, setIsDisabled] = useState(false);
  const timer = useRef(null);
  const { sendRequest } = useHttp();
  const navigate = useNavigate();

  // disable dbl-click
  const goNext = () => {
    setIsDisabled(true);
    setTimeout(() => {
      setIsDisabled(false);
    }, 500);
    
    props.onNext();
  }

  useEffect(() => {
    if (valid) {
      setIsDisabled(false);
    }
  }, [valid]);


  const showDialogHandler = () => {
    setShowDialog('mdc-dialog--opening');
    setTimeout(() => {
      setShowDialog('mdc-dialog--opening mdc-dialog--open');
    }, 10);
    setTimeout(() => {
      setShowDialog('mdc-dialog--open');
    }, 140);
  };

  const hideDialogHandler = () => {
    setShowDialog('mdc-dialog--closing mdc-dialog--open');
    setTimeout(() => {
      setShowDialog('');
    }, 70);
  };

  const unlockHandler = useCallback(() => {
    setDialogDisabled(true);
    const data = new FormData();
    data.append("unlock", 1);
    
    sendRequest({
      url: window.location.pathname,
      method: 'POST',
      body: data
    }, (response) => {
      navigate(window.location.pathname);
      setDialogDisabled(false);
      hideDialogHandler();
    });
    
  }, [sendRequest]);

  const showNext = (lastOrd - ord) > 0;

  // time left
  const leftMinutes = timeLeft ? ("0" + Math.floor(counter / 60)).slice(-2) : '';
  const leftSeconds = timeLeft ? ("0" + Math.floor((counter % 60))).slice(-2) : '';
  const leftIcon = (counter && counter > 0) ? 'hourglass_top' : 'hourglass_bottom';
  const leftLabel = counter > 0 ? leftMinutes + ':' + leftSeconds : dictionary['time_is_up'];

  useEffect(() => {
    // if (timeLeft) {
      setCounter(timeLeft);
    // }

    // run counter
    if (typeof timer.current !== null) {
      clearTimeout(timer.current);
      // console.log('clear')
    }
  }, [timeLeft]);

  useEffect(() => {
    if (counter && counter > 0) {
      timer.current = setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    }

    return () => {
      clearTimeout(timer.current);
    };
  }, [counter]);
  
  return (<footer className="flex justify-between items-center pa3 w-100 fixed left-0 bottom-0">
    <div className="w116">
      {(timeLeft) && <div className={`btn btn-l btn-blur bg-blur ${counter < 16 ? 'time-end' : ''}`} title="Limit czasu">
        <i className="material-icons mr2" aria-hidden="true">{leftIcon}</i>
        {leftLabel}
      </div>}
    </div>
    <div className="flex items-center">
      <div className="w80"></div>
      <nav className="flex items-center bg-blur br-l">
        <button onClick={props.onPrev}  className="btn btn-l">
          <i className="material-icons mr2" aria-hidden="true">arrow_back</i>
          {props.backLabel}
        </button>
        {showNext && <button disabled={isDisabled} onClick={goNext} className={`btn btn-l btn-primary ${(valid === true) ? "" : "btn-inactive"}`}>{props.commandLabel}</button>}
      </nav>
      <div className="w80">
        {showUnlock && <button onClick={showDialogHandler} className="icon-btn btn-l ml3 bg-blur" title="Otwórz do edycji">
          <span className="material-icons c-onsurface-lighter">lock</span>
        </button>}
      </div>
    </div>
    <div className="w116">
      {props.passed && <div className="proj-stage flex items-center h-100 ph4 z-1 bg-blur onsurface-light cursor-help" title={props.passedTitle}>
        <div className="material-icons">how_to_reg</div><div className="f5 fw5 ml2">{props.passed}</div>
      </div>}
    </div>

    <div className={`mdc-dialog dialog-fullscreen ${showDialog}`}>
      <div className='mdc-dialog__container'>
        <div className="mdc-dialog__surface">
          <div className="mdc-dialog__content">
          <h3></h3>
          <div className='f3'>{dictionary['unlock_confirm']}</div>
          </div>
          <div className="mdc-dialog__actions">
            <button onClick={hideDialogHandler} className="matter-button-text mr2">{dictionary['cancel']}</button>
            <button disabled={dialogDisabled} onClick={unlockHandler} className="matter-button-text btn-primary">{dictionary['unlock']}</button>
          </div>
        </div>
      </div>
      <div className="mdc-dialog__scrim"></div>
    </div>
  </footer>)
}

export default ProjectFooter;