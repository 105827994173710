import { useEffect } from "react";

const ProjectStart = (props) => {
  const { current, ord, project, dictionary, onValid, readOnly } = props;

  const zoom = () => {
    window.Swal.fire({
      title: '',
      html: '<div class="f-headline mt7 c-onsurface">https://4futures.eu/' + project.slug + '</div><div class="f-hash mv6 c-onsurface">' + project.slug + '</div>',
      confirmButtonText: 'Ok',
      width: '85%'
    })
  };
  
  // valid
  useEffect(() => {
    let valid;
    if (current < ord && readOnly === true) {
      valid = false; // uczen na ekranie grupowym czeka na zatwierdzenie nauczyciela
    } else if (current >= ord) {
      valid = true; // skip - krok juz zapisany
    } else {
      valid = true; // walidacja
    }
    onValid(valid);
  });

  return (<main className="main-section flex w-100 items-center justify-center">
    <section className="w-100">

      <div className="container-lg w-100 ph3 center">
        <div className="group-link primary-container br-xl pt7 pr7 pl7 relative">
          <p className="f1 fw6 mt0 mb4">{dictionary.title}</p>
          <p className="f-5 fw6 mt0 mb0">https://4futures.eu/{project.slug}</p>
          <img src="/images/new-project.svg" alt="" className="db mw-100 mt7 ml-auto" />
          <button type="button" onClick={zoom} className="icon-btn btn-l btn--fullscreen bg-primary fc-on-primary absolute"><span className="material-icons">fullscreen</span></button>
        </div>
      </div>

    </section>
  </main>);
}

export default ProjectStart;