import { useState, useEffect } from "react";

const RoadmapPeriodEdit = (props) => {
  const { item, dictionary, onPeriodSave, onDelete } = props;
  const [showDialog, setShowDialog] = useState('');
  const [timeHorizon, setTimeHorizon] = useState((item) ? item.data.time_horizon : '');
  const [occupation, setOccupation] = useState((item) ? item.data.occupation : '');
  const [salary, setSalary] = useState((item) ? item.data.salary : '');
  const [timeHorizonError, setTimeHorizonError] = useState(false);
  const [occupationError, setOccupationError] = useState(false);
  const [salaryError, setSalaryError] = useState(false);
  const id = (item) ? item.id : null;

  const showDialogHandler = () => {
    setTimeHorizonError(false);
    setOccupationError(false);
    setSalaryError(false);

    setShowDialog('mdc-dialog--opening');
    setTimeout(() => {
      setShowDialog('mdc-dialog--opening mdc-dialog--open');
    }, 10);
    setTimeout(() => {
      setShowDialog('mdc-dialog--open');
    }, 140);
  };

  const hideDialogHandler = () => {
    setShowDialog('mdc-dialog--closing mdc-dialog--open');
    setTimeout(() => {
      setShowDialog('');
    }, 70);
  };

  useEffect(() => {
    if (item) {
      setTimeHorizon(item.data.time_horizon);
      setOccupation(item.data.occupation);
      setSalary(item.data.salary);

      showDialogHandler();
    } else {
      setTimeHorizon('');
      setOccupation('');
      setSalary('');
      // hideDialogHandler();
      // console.log('hide');
    }
  }, [item]);

  const changeHorizonHandler = (e) => {
    setTimeHorizon(e.target.value);
    if (timeHorizonError && e.target.value !== '') {
      setTimeHorizonError(false);
    }
  };

  const changeOccupationHandler = (e) => {
    setOccupation(e.target.value);
    if (occupationError && e.target.value !== '') {
      setOccupationError(false);
    }
  };

  const changeSalaryHandler = (e) => {
    setSalary(e.target.value);
    if (salaryError && e.target.value !== '') {
      setSalaryError(false);
    }
  };

  const deleteHandler = () => {
    hideDialogHandler();
    setTimeout(() => {
      onDelete(id);
    }, 120);
  };

  const saveHandler = () => {
    if (timeHorizon !== '' && occupation !== '' && salary !== '') {
      onPeriodSave({
        id: id,
        data: {
          time_horizon: timeHorizon,
          occupation: occupation,
          salary: salary
        }
      });
      setShowDialog('');
    } else {
      if (timeHorizon === '') {
        setTimeHorizonError(true);
      }
      if (occupation === '') {
        setOccupationError(true);
      }
      if (salary === '') {
        setSalaryError(true);
      }
    }
  }

  return (<div className={`mdc-dialog dialog-fullscreen ${showDialog}`}>
    <div className='mdc-dialog__container'>
      <div className="mdc-dialog__surface">
        <h2 className="mdc-dialog__title text-xl font-semibold" id="body-title">{dictionary['period']}</h2>
        <div className="mdc-dialog__content">
          <div className={`mb3 pt3 ${(timeHorizonError) ? 'error' : ''}`}>
            <div className="pl2 mb1 dialog-label">{dictionary['horizon_label']}</div>
            <div className="x-input w-100 w-factor-l">
              <input type="text" placeholder={dictionary['horizon_placeholder']} value={timeHorizon} onInput={changeHorizonHandler} />
            </div>
            <div className="pt1 pl2 f6 dialog-hint">{dictionary['horizon_hint']}</div>
          </div>
          <div className={`mb3 ${(occupationError) ? 'error' : ''}`}>
            <div className="pl2 mb1 dialog-label">{dictionary['occupation_label']}</div>
            <div className="x-input w-100 w-factor-l">
              <input type="text" placeholder={dictionary['occupation_title']} value={occupation} onInput={changeOccupationHandler} />
            </div>
            <div className="pt1 pl2 f6 dialog-hint">{dictionary['occupation_hint']}</div>
          </div>
          <div className={`${(salaryError) ? 'error' : ''}`}>
            <div className="pl2 mb1 dialog-label">{dictionary['salary_label']}</div>
            <div className="x-input w-100 w-factor-l">
              <input type="text" placeholder={dictionary['salary_title']} value={salary} onInput={changeSalaryHandler} />
            </div>
            <div className="pt1 pl2 f6 dialog-hint">{dictionary['salary_hint']}</div>
          </div>
        </div>
        <div className="mdc-dialog__actions">
          {id !== null && <button onClick={deleteHandler} className="matter-button-text btn-del mr2">{dictionary['delete']}</button>}
          <div className="flex-grow"></div>
          <button onClick={hideDialogHandler} className="matter-button-text mr2">{dictionary['cancel']}</button>
          <button onClick={saveHandler} className="matter-button-text btn-primary">{dictionary['save_alt']}</button>
        </div>
      </div>
    </div>
    <div className="mdc-dialog__scrim"></div>
  </div>);
}

export default RoadmapPeriodEdit;