import { useEffect, useCallback, useContext } from "react";
// import FactorProgress from "./FactorProgress";
import ChooseDrag from "./ChooseDrag";
import PageContext from "../context/page-context";

const ChooseGroup = (props) => {
  const { current, ord, project, state, factors, dictionary, type, compute_attr, onUpdate, onValid, readOnly } = props;
  const { onHelp } = useContext(PageContext);
  // const items = factors[type];

  // progress
  // const maxCompare_attr = Math.max(...items.map(o => o.compare_attr));
  // items.sort((a, b) => (a.compare_attr > b.compare_attr) ? -1 : 1);
  
  // update parent state
  const updateCurrentStateHandler = useCallback((data) => {
    onUpdate(data);
  }, [onUpdate]);
  
  // page validation
  useEffect(() => {
    let valid;
    if (readOnly === true && current < ord) {
      valid = false; // uczen na ekranie grupowym czeka na zatwierdzenie nauczyciela
    } else if (current >= ord) {
      valid = true; // skip - krok juz zapisany
    } else {
      valid = state[type] && state[type].filter((obj) => obj[compute_attr] === 1).length > 0;
    }
    onValid(valid);
  });

  /* useEffect(() => {
    // const valid = state[type] && state[type].length > 0 && state[type].length < 4;
    const valid = state[type] && state[type].filter((obj) => obj[compute_attr] === 1).length > 0;
    
    onValid(valid);
  }); */

  // page help modal
  const  help = () => {
    onHelp({ html: dictionary.help });
  };

  const command = dictionary.command.replace('{area}', project.area);
  
  return (<main className="main-section flex w-100 items-center justify-center">
    <section className="step wide w-100 w-80-l ph3 ph0-l">
      <div className="command w-80-l flex center mb5">
        <div className="f3" dangerouslySetInnerHTML={{ __html: command }}></div>
        <div className="pl4">
          <button className="icon-btn btn-primary btn-m" onClick={help}>
            <span className="material-icons">question_mark</span>
          </button>
        </div>
      </div>
      
      <ChooseDrag type={type} factors={factors[type] ?? []} state={state[type] ?? []} compute_attr={compute_attr} readOnly={readOnly} tip={dictionary.indications} dicFactor={dictionary.factor} onUpdate={updateCurrentStateHandler} />

    </section>      
  </main>);
}

export default ChooseGroup;