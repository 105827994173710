import { useEffect, useCallback, useContext } from "react";
import FactorsCheckbox from "./FactorsCheckbox";
import PageContext from "../context/page-context";

const ChooseDouble = (props) => {
  const { current, ord, state, factors, project, dictionary, type, compute_attr, onUpdate, onValid, onAddFactor, readOnly, addSymbols } = props;
  const { onHelp } = useContext(PageContext);
  
  const types = type.split(',');
  const type1 = types[0];
  const type2 = types[1];

  // command
  const command = dictionary.command.replace('{area}', project.area).replace('{scenario_name}', project.scenario_name);

  // update parent state
  const updateCurrentStateHandler = useCallback((data) => {
    onUpdate(data);
  }, [onUpdate]);
  
  // page validation
  useEffect(() => {
    let valid;
    if (current >= ord) {
      valid = true; // juz zapisane
    } else if (readOnly == true && current < ord) {
      valid = true; // nauczyciel bierny
    } else {
      valid = state[type1] && state[type2] && state[type1].length > 0 && state[type2].length > 0 && state[type1].length + state[type2].length < 11;
    }
    onValid(valid);
  });

  // task progress
  let taskProgress = false;
  let taskIcon = false;
  if (!readOnly) {
    taskProgress = (state[type1]) ? state[type1].length : 0;
    taskProgress += (state[type2]) ? state[type2].length : 0;
    taskIcon = (state[type1] && state[type2] && state[type1].length > 0 && state[type2].length > 0 && taskProgress < 11) ? 'task_alt' : 'block';
  }

  // add new factor
  const addFactorHandler = () => {
    onAddFactor();
  };

  // page help modal
  const  help = () => {
    onHelp({ html: dictionary.help });
  };
  
  return (<main className="main-section flex w-100 items-center justify-center">
    <section className="step wide w-100 w-80-l ph3 ph0-l">
      <div className="command w-80-l flex center mb5">
        <div className="f3" dangerouslySetInnerHTML={{ __html: command }}></div>
        <div className="pl4">
          <button className="icon-btn btn-primary btn-m" onClick={help}>
            <span className="material-icons">question_mark</span>
          </button>
        </div>
      </div>
      
      <div className="flex flex-wrap nh3">
        <div className="w-100 w-50-l pr1-l mb3 mb0-l">
          <FactorsCheckbox onUpdate={updateCurrentStateHandler} onAddFactor={addFactorHandler} title={dictionary[type1]} type={type1} factors={factors[type1]} state={state[type1] ?? []} compute_attr={compute_attr} dictionary={dictionary} readOnly={readOnly} addSymbols={addSymbols} />
        </div>
        <div className="w-100 w-50-l pl1-l technological">
          <FactorsCheckbox onUpdate={updateCurrentStateHandler} onAddFactor={addFactorHandler} title={dictionary[type2]} type={type2} factors={factors[type2]} state={state[type2] ?? []} compute_attr={compute_attr} dictionary={dictionary} readOnly={readOnly} addSymbols={addSymbols} />
        </div>
      </div>
    </section>
    {taskProgress !== false && <div className="fixed bottom-1 right-1 proj-stage flex items-center h-100 ph4 z-1 bg-blur onsurface-light cursor-help">
      {taskProgress > 0 && <div className="material-icons mr2">{taskIcon}</div>}<div className="f5 fw5">{taskProgress}/10</div>
    </div>} 
  </main>);
}

export default ChooseDouble;
