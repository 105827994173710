import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home, { loader as pageLoader } from './pages/Home.js';
import Page from './pages/Page.js';
import ProjectPage from './pages/ProjectPage.js';

import './styles/index.scss';

const router = createBrowserRouter([
  { 
    path: '/', 
    element: <Home />,
    loader: pageLoader
  },
  { 
    path: '/p/:slug', 
    element: <Page />,
    loader: pageLoader
  },
  { 
    path: '/:slug/:ord?', 
    element: <ProjectPage />,
    loader: pageLoader
  }
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
