import { useState } from "react";
import ExampleScenarios from "./ExampleScenarios";

const ScenariosExample = (props) => {
  const [showDialog, setShowDialog] = useState('');
  const [cover, setcover] = useState('');

  const showDialogHandler = () => {
    setShowDialog('mdc-dialog--opening');
    setTimeout(() => {
      setShowDialog('mdc-dialog--opening mdc-dialog--open');
    }, 10);
    setTimeout(() => {
      setShowDialog('mdc-dialog--open');
    }, 140);
  };

  const hideDialogHandler = () => {
    setShowDialog('mdc-dialog--closing mdc-dialog--open');
    setTimeout(() => {
      setShowDialog('');
    }, 70);
  };

  const setCoverHandler = (imageUrl) => {
    setcover(imageUrl);
  };

  const backgroundStyle = {
    backgroundImage: `url(${cover})`
  };
  
  return (<div>
    <button className="icon-btn btn-primary btn-m mt1" onClick={showDialogHandler}>
      <span className="material-icons">tips_and_updates</span>
    </button>
    <div className={`mdc-dialog dialog-fullscreen ${showDialog}`}>
      <div className='mdc-dialog__container'>
        <div className={`mdc-dialog__surface surface-example ${(cover) ? 'dialog-has-cover' : ''}`} style={backgroundStyle}>
          <div className="dialog-cover z-0"></div>
          <div className="mdc-dialog__title text-xl font-semibold" id="body-title">{!cover && <span className="scenarios-title f4 pl2">{props.dictionary['example_scenarios']}</span>}</div>
          <div className="mdc-dialog__content relative z-1">
            <ExampleScenarios dictionary={props.dictionary} onSetCover={setCoverHandler} />
          </div>
          <div className="mdc-dialog__actions"></div>
          <button onClick={hideDialogHandler} className="icon-btn btn-textx btn-m absolute top-2 right-2 close-examle z-1" title="Zamknij">
          <span className="material-icons">clear</span>
          </button>
        </div>
      </div>
      <div className="mdc-dialog__scrim"></div>
    </div>
  </div>);
}

export default ScenariosExample;