const FactorRate = (props) => {
  const { id, compute_attr, name, type, label, symbol, state, readOnly, onUpdate } = props;
  const checked = [
    false, false, false, false, false, false, false, false
  ];

  if (state && state[compute_attr]) {
    checked[state[compute_attr]] = true;
  }
  
  const updateFactorHandler = (event) => {
    onUpdate({ id: id, val: parseInt(event.target.value) });
  }
  
  return (<div className={"factor-progress with-rating " + type}>
    <span className="label"><strong>{symbol}</strong>{label}</span>
    <div className={"rating flex-wrap" + ((readOnly) ? ' disabled' : '')}>
      <input type="radio" name={name} value="7" className="r7" id={"7" + id} checked={checked[7]} onChange={updateFactorHandler} disabled={readOnly} /><label htmlFor={"7" + id}></label>
      <input type="radio" name={name} value="6" className="r6" id={"6" + id} checked={checked[6]} onChange={updateFactorHandler} disabled={readOnly} /><label htmlFor={"6" + id}></label>
      <input type="radio" name={name} value="5" className="r5" id={"5" + id} checked={checked[5]} onChange={updateFactorHandler} disabled={readOnly} /><label htmlFor={"5" + id}></label>
      <input type="radio" name={name} value="4" className="r4" id={"4" + id} checked={checked[4]} onChange={updateFactorHandler} disabled={readOnly} /><label htmlFor={"4" + id}></label>
      <input type="radio" name={name} value="3" className="r3" id={"3" + id} checked={checked[3]} onChange={updateFactorHandler} disabled={readOnly} /><label htmlFor={"3" + id}></label>
      <input type="radio" name={name} value="2" className="r2" id={"2" + id} checked={checked[2]} onChange={updateFactorHandler} disabled={readOnly} /><label htmlFor={"2" + id}></label>
      <input type="radio" name={name} value="1" className="r1" id={"1" + id} checked={checked[1]} onChange={updateFactorHandler} disabled={readOnly} /><label htmlFor={"1" + id}></label>
      <span className="indicator"></span>
    </div>
  </div>);
}

export default FactorRate;