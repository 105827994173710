import { Fragment, useCallback } from "react"
import { useNavigate } from "react-router-dom";
import PageHeader from "./PageHeader";
import useHttp from "../hooks/use-http";

const ChooseGroupScenario = (props) => {
  const { dictionary } = props;
  const { sendRequest } = useHttp();
  const navigate = useNavigate();

  // genrate new project
  const generateProjectHandler = useCallback((event) => {
    const idScenario = event.target.closest('.pointer').dataset.scenario;

    if (idScenario) {
      sendRequest({
        url: '/create-project/' + idScenario,
        method: 'GET'
      }, (response) => {
        console.log(response);
        /* if (response.id_user) {
          localStorage.setItem('id_user', response.id_user);
        } */
        if (response.slug) {
          navigate('/' + response.slug);
        }
      });
    }
  }, [sendRequest]);

  return (<Fragment>
    <PageHeader title={dictionary['group-work']} />
    <main className="main-section flex w-100 items-center justify-center">

    <section className="w-100">

      <div className="container w-100 ph3 center">
        <h2 className="f1 mt0 mb4">{dictionary['select-area']}</h2>
        <div className="row flex flex-wrap">
          <div className="w-100 w-50-ns pa3">
            <div data-scenario="4" onClick={generateProjectHandler} className="pointer">
              <div className="area__item surface br-lg overflow-hidden relative">
                <div>
                  <img src="/images/area_rynek_pracy.jpg" alt="Labour market" className="db mw-100 ml-auto br-lg" />
                </div>
                <div className="flex items-center ph4 pv5">
                  <h3 className="f2 fw6 mv0">{ dictionary['labour-market'] }</h3>
                  <i className="material-icons ml-auto" aria-hidden="true">arrow_forward</i>
                </div>
              </div>
            </div>
          </div>
          <div className="w-100 w-50-ns pa3">
            <div data-scenario="5" onClick={generateProjectHandler} className="pointer">
              <div className="area__item surface br-lg overflow-hidden relative">
                <div>
                  <img src="/images/area_dowolny.jpg" alt="Labour market" className="db mw-100 ml-auto br-lg" />
                </div>
                <div className="flex items-center ph4 pv5">
                  <h3 className="f2 fw6 mv0">{ dictionary['any-analysis-area'] }</h3>
                  <i className="material-icons ml-auto" aria-hidden="true">arrow_forward</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>

    </main>
    </Fragment>);
}

export default ChooseGroupScenario;
