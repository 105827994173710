import {useDroppable} from '@dnd-kit/core';

const Droppable = (props) => {
  const {isOver, setNodeRef} = useDroppable({
    id: props.id,
  });
  const style = {
    opacity: isOver ? '0.8' : undefined,
    borderColor: isOver ? '#391952' : undefined
  };
  
  return (
    <div ref={setNodeRef} style={style} className={props.className}>
      {props.children}
    </div>
  );
};

export default Droppable;