import { useState, useEffect } from "react";

const RoadmapSkillEdit = (props) => {
  const { item, dictionary, onSave, onDelete } = props;
  const [showDialog, setShowDialog] = useState('');
  const [data, setData] = useState((item) ? item.data : '');
  const [error, setError] = useState(false);
  const id = (item) ? item.id : null;
  const idPeriod = (item) ? item.idPeriod : null;

  const showDialogHandler = () => {
    setError(false);
    setShowDialog('mdc-dialog--opening');
    setTimeout(() => {
      setShowDialog('mdc-dialog--opening mdc-dialog--open');
    }, 10);
    setTimeout(() => {
      setShowDialog('mdc-dialog--open');
    }, 140);
  };

  const hideDialogHandler = () => {
    setShowDialog('mdc-dialog--closing mdc-dialog--open');
    setTimeout(() => {
      setShowDialog('');
    }, 70);
  };

  useEffect(() => {
    if (item) {
      setData(item.data);

      showDialogHandler();
    } else {
      setData('');
      // hideDialogHandler();
      // console.log('hide');
    }
  }, [item]);

  const changeDataHandler = (e) => {
    setData(e.target.value);
    if (error && e.target.value !== '') {
      setError(false);
    }
  };

  const saveHandler = () => {
    if (data) {
      onSave({
        idPeriod: idPeriod,
        id: id,
        data: data
      });
      hideDialogHandler();
    } else {
      setError(true);
    }
  }

  const deleteHandler = () => {
    onDelete(idPeriod, id);
    hideDialogHandler();
  };

  return (<div className={`mdc-dialog dialog-fullscreen ${showDialog}`}>
    <div className='mdc-dialog__container'>
      <div className="mdc-dialog__surface">
        <h2 className="mdc-dialog__title text-xl font-semibold" id="body-title">{dictionary['skills_label']}</h2>
        <div className="mdc-dialog__content">
          <div className={`mb3 pt3 ${(error) ? 'error' : ''}`}>
            <div className="x-input w-100 w-factor-l">
              <input type="text" placeholder={dictionary['skills_title']} value={data} onInput={changeDataHandler} />
            </div>
            <div className="pt1 pl2 f6 dialog-hint-error">{dictionary['required']}</div>
          </div>
        </div>
        <div className="mdc-dialog__actions">
          {id !== null && <button onClick={deleteHandler} className="matter-button-text btn-del mr2">{dictionary['delete']}</button>}
          <div className="flex-grow"></div>
          <button onClick={hideDialogHandler} className="matter-button-text mr2">{dictionary['cancel']}</button>
          <button onClick={saveHandler} className="matter-button-text btn-primary">{dictionary['save_alt']}</button>
        </div>
      </div>
    </div>
    <div className="mdc-dialog__scrim"></div>
  </div>);
}

export default RoadmapSkillEdit;