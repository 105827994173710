const FactorProgress = (props) => {
  const { label, type, symbol, compare_attr, maxCompare_attr } = props;

  const progress = (maxCompare_attr && compare_attr && maxCompare_attr > 0) ? ((compare_attr / maxCompare_attr) * 100) : 0;

  return (<div className={`factor-progress with-tail ${type ? type : ''}`}>
    <span className="indicator" style={{width: progress + '%'}}></span>
    <span className="label">{symbol && <strong>{symbol}</strong>}{label}</span>
    <strong className="tail">{ compare_attr }</strong>
  </div>);
}

export default FactorProgress;