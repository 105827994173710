import LangSwitch from "./LangSwitch";

const ProjectHeader = (props) => {
  const {project, ord, dictionary, stage } = props;
  const progress = 100 * (ord / project.steps);

  return (<header className="flex items-center pa3 w-100 fixed justify-between">
    <div className={!stage ? 'w3' : ''}>
      {stage && <div className="proj-stage flex items-center h-100 ph4 z-1 onsurface-light ff-title fw6 bg-blurx">
        <div className="mr4 f5">{stage}</div><div className="stg f5">{dictionary[stage]}</div>
      </div>}
    </div>
    <div className="proj-title dib relative bg-blur">
      <div className="proj-progress z-0" style={{width: progress + '%'}}></div>
      { project.question && <div className="flex items-center h-100 ph4 z-1 relative">
        <h1 className="f3 fw6 lh-solid">{project.question}</h1>
        <div className="proj-separator z-0"></div>
        <div className="f3 fw6 ff-title">{project.time_horizon}</div>
      </div> }
      { !project.question && <div className="flex items-center h-100 ph4 z-1 relative"><h1 className="f2 fw6">{dictionary['header']}</h1></div> }
    </div>
    <div><LangSwitch /></div>
  </header>);
}

export default ProjectHeader;

/* <button className={`icon-btn btn-l btn-text ${(props.loading === true) ? "loading" : ""}`} onClick={props.onSync}>
      <span className="material-icons onsurface-lighter">autorenew</span>
    </button> */