import { useEffect } from "react";

const ProjectSection = (props) => {
  const { current, ord, project, dictionary, onValid, readOnly } = props;

  // valid
  useEffect(() => {
    let valid;
    if (readOnly === true && current < ord) {
      valid = false; // uczen na ekranie grupowym czeka na zatwierdzenie nauczyciela
    } else if (current >= ord) {
      valid = true; // skip - krok juz zapisany
    } else {
      valid = true; // walidacja
    }
    onValid(valid);
  });

  const content = (dictionary.content !== undefined) ? dictionary.content.replace('{area}', project.area).replace('{scenario_name}', project.scenario_name) : '';
  const content2 = (dictionary.content2 !== undefined) ? dictionary.content2.replace('{area}', project.area) : '';
  
  return (<main className="main-section flex w-100 items-center justify-center">
    <section className="w-100 section--two-cols">

      <div className="container-lg w-100 ph3 center">
        <div className="flex flex-wrap items-center">
          <div className="col w-100 w-60-l">
            <div className="primary-container br-xl f3 pv6 ph7 lh-copy" dangerouslySetInnerHTML={{ __html: content }}>
            </div>
          </div>
          <div className="col w-100 w-40-l">
            <div className="secondary-container text-with-icons br-xl f4 pv6 ph7 relative">
              <span className="circle circle--lg light flex items-center justify-center mb4">
                <i className="material-icons f3" aria-hidden="true">tips_and_updates</i>
              </span>
              <div className="lh-copy" dangerouslySetInnerHTML={{ __html: content2 }}></div>
            </div>
          </div>
        </div>
      </div>

    </section>
  </main>);
}

export default ProjectSection;