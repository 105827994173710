import { useEffect, useCallback, useContext, useState } from "react";
import FactorsRate from "./FactorsRate";
import PageContext from "../context/page-context";

const RateFactors = (props) => {
  const { current, ord, state, factors, project, dictionary, type, compute_attr, onUpdate, onValid, readOnly } = props;
  const { onHelp } = useContext(PageContext);
  const [mounted, setMounted] = useState(false);
  const helpText = dictionary.help;

  // command
  const command = (dictionary.command !== undefined) ? dictionary.command.replace('{time_horizon}', project.time_horizon).replace('{area}', project.area) : '';
  
  // update parent state
  const updateCurrentStateHandler = useCallback((data) => {
    onUpdate(data);
  }, [onUpdate]);
  
  // page validation
  useEffect(() => {
    let valid;
    if (current >= ord) {
      valid = true; // juz zapisane
    } else if (readOnly == true && current < ord) {
      valid = true; // nauczyciel bierny
    } else {
      const countRated = (state[type]) ? state[type].filter((obj) => obj[compute_attr]).length : 0;
      const countFactors = (factors[type]) ? factors[type].length : 0;
      valid = countRated === countFactors;
    }
    onValid(valid);
  });

  // page help modal
  const  help = () => {
    onHelp({ html: helpText });
  };

  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    }, 140);
  });

  return (<main className="main-section flex w-100 items-center justify-center">
    <section className="step w-100 w-80-l ph3 ph0-l">
      <div className="command w-80-l flex center mb4 mb6-l">
        <div className="f3" dangerouslySetInnerHTML={{ __html: command }}></div>
        <div className="pl4">
          { helpText && <button onClick={help} className="icon-btn btn-primary btn-m">
            <span className="material-icons">question_mark</span>
          </button> }
        </div>
      </div>

      <FactorsRate onUpdate={updateCurrentStateHandler} type={type} factors={factors[type] ?? []} state={state[type] ?? []} compute_attr={compute_attr} readOnly={readOnly} tipLo={dictionary['tip-low']} tipHi={dictionary['tip-high']} dictionary={dictionary} />
      {dictionary.info !== undefined && <div className={`bottom-info flex animate-enter-down ${mounted ? 'animated' : ''}`}>
        <div><span className="material-icons mr2">info_outline</span></div>
        <div dangerouslySetInnerHTML={{ __html: dictionary.info }}></div>
      </div>}
    </section>
  </main>);
}

export default RateFactors;

// const items = Object.values(factors)[0];