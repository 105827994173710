const FactorProgressDual = (props) => {
  const maxAttr = 7;
  const checked = (props.state && props.state[props.compute_attr] === 1) ? true : false;
  const progressAttr = (props.compare_attr) ? ((props.compare_attr / maxAttr) * 100) / 2 : 0;
  const progressAttr2 = (props.compare_attr2) ? ((props.compare_attr2 / maxAttr) * 100) / 2 : 0;

  const attrStyle = (progressAttr) ? { width: progressAttr + '%' } : {};
  const attrStyle2 = (progressAttr2) ? { width: progressAttr2 + '%' } : {};

  return (<label className={`factor-progress factor-dual ${(props.type) ? props.type : ""}`}>
    <span className="indicatorx w-100"></span>
    <span className="attr" style={attrStyle}></span>
    <span className="attr2" style={attrStyle2}></span>
    <span className="label">{props.symbol && <strong>{props.symbol} </strong> }{props.label}</span>
  </label>);
}

export default FactorProgressDual;