import { useState, useEffect } from "react";
import useHttp from "../hooks/use-http";

const ExampleScenarios = (props) => {
  const { dictionary, onSetCover } = props;
  const { ex_factor1, ex_factor2, ex1_name, ex2_name, ex3_name, ex4_name, back, example_scenarios, click_scenario } = dictionary;
  const { isLoading, error, sendRequest } = useHttp();
  const [scenario, setScenario] = useState(null);
  const [scenarios, setScenarios] = useState({});

  const scenarioImages = [
    '',
    '/images/scenario_rajska_plaza.jpg',
    '/images/scenario_cyfrowi_nomadzi.jpg',
    '/images/scenario_zascianek.jpg',
    '/images/scenario_czysciec.jpg'
  ];

  const fetchScenarios = () => {
    sendRequest({
      url: '/get-example-scenarios'
    }, (response) => {
      if (response.dictionary) {
        setScenarios(response.dictionary);
      }
    });
  };
  
  const setScenarioHandler = (id) => {
    setScenario(id);
    onSetCover(scenarioImages[id] ?? null);
  };

  useEffect(() => {
    fetchScenarios();
  }, []);

  return (<div className="flex justify-center items-center h-100">
    {scenario && <section className="ph6-l example-scenario lh-copy scenario-description">
      <button onClick={() => setScenarioHandler(null)}  className="btn btn-l btn-scenario-back">
        <i className="material-icons mr2" aria-hidden="true">arrow_back</i>
        {back}
      </button>
      <div className="pt4 pr6-l" dangerouslySetInnerHTML={{ __html: scenarios[scenario] }}></div>
    </section>}
    {!scenario && <div>
    <section className="scenarios w-100 ph3 ph6-l relative">
      <div className="flex justify-center mb2">
        <div className="label-y center social">
          <div>{dictionary['high-level']}</div> 
          {scenarios['ex_factor1'] && <div className="fw5">{scenarios['ex_factor1']}</div>}
        </div>
      </div>

      <div className="relative mh3">
        <div className="max-example center relative">
          <div className="grid grid-cols-2 gap-8">
            <div className="card scenario-card scenario-select ph4 flex flex-col items-center">
              <div className="scenario-cover z-1 with-image" style={{ backgroundImage: `url(${scenarioImages[2]})`}}></div>
              <label className="w-100 f2 ff-title fw6 tc relative z-2 scenario-title ph4 h-100 flex items-center justify-center pointer" onClick={() => setScenarioHandler(2)}>
                <div className="dib over-wrap">{scenarios['ex2_name']}</div>
              </label>
            </div>
            <div className="card scenario-card scenario-select ph4 flex flex-col items-center">
              <div className="scenario-cover z-1 with-image" style={{ backgroundImage: `url(${scenarioImages[1]})`}}></div>
              <label className="w-100 f2 ff-title fw6 tc relative z-2 scenario-title ph4 h-100 flex items-center justify-center pointer" onClick={() => setScenarioHandler(1)}>
                <div className="dib over-wrap">{scenarios['ex1_name']}</div>
              </label>
            </div>
            <div className="card scenario-card scenario-select ph4 flex flex-col items-center">
              <div className="scenario-cover z-1 with-image" style={{ backgroundImage: `url(${scenarioImages[3]})`}}></div>
              <label className="w-100 f2 ff-title fw6 tc relative z-2 scenario-title ph4 h-100 flex items-center justify-center pointer" onClick={() => setScenarioHandler(3)}>
                <div className="dib over-wrap">{scenarios['ex3_name']}</div>
              </label>
            </div>
            <div className="card scenario-card scenario-select ph4 flex flex-col items-center">
              <div className="scenario-cover z-1 with-image" style={{ backgroundImage: `url(${scenarioImages[4]})`}}></div>
              <label className="w-100 f2 ff-title fw6 tc relative z-2 scenario-title ph4 h-100 flex items-center justify-center pointer" onClick={() => setScenarioHandler(4)}>
                <div className="dib over-wrap">{scenarios['ex4_name']}</div>
              </label>
            </div>
            <div className="scenario-x-axis flex items-center">
              <div className="x-arrows"></div>
            </div>
            <div className="scenario-y-axis flex justify-center">
              <div className="y-arrows"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="label-x label-left maxw-label center economic">
        <div>{dictionary['low-level']}</div> 
        {scenarios['ex_factor2'] && <div className="fw5">{scenarios['ex_factor2']}</div>}
      </div>
      <div className="label-x label-right maxw-label center economic">
        <div>{dictionary['high-level']}</div> 
        {scenarios['ex_factor2'] && <div className="fw5">{scenarios['ex_factor2']}</div>}
      </div>

      <div className="flex justify-center mt2">
        <div className="label-y center social">
          <div>{dictionary['low-level']}</div> 
          {scenarios['ex_factor1'] && <div className="fw5">{scenarios['ex_factor1']}</div>}
        </div>
      </div>
  
    </section>
    <div className="f6 tc scenarios-info">{scenarios['click_scenario']}</div>
    </div>}
  </div>);
}

export default ExampleScenarios;