import { useState, useEffect, useCallback } from "react";
import useHttp from "../hooks/use-http";

const ScenarioNameCard = (props) => {
  const { id, state, pholder, onUpdate, readOnly } = props;
  const [name, setName] = useState(state.name ?? '');
  const [image, setImage] = useState(state.image ?? '');
  const { isLoading, error, sendRequest } = useHttp();

  const placeholder = pholder.replace('{nr}', id);

  const updateParentStateHandler = () => {
    onUpdate({
      id: id,
      state: { id: id, name: name, image: image }
    });
  };

  const nameHandler = (event) => {
    setName(event.target.value);
  };

  // add new factor
  const uploadHandler = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const data = new FormData();
      data.append('image', selectedFile);
      sendRequest({
        url: '/upload-image',
        method: 'POST',
        body: data
      }, (response) => {
        if (response.image) {
          setImage(response.image);
          onUpdate({
            id: id,
            state: { id: id, name: name, image: response.image }
          });
        }
        event.target.value = ''; // clear file input
      });
    }
  };

  // update readonly users name on state change
  useEffect(() => {
    if (readOnly && state.name != name) {
      setName(state.name);
    }
  }, [readOnly, state.name, name]);

  // update readonly users image on state change
  useEffect(() => {
    if (readOnly && state.image != image) {
      setImage(state.image);
    }
  }, [readOnly, state.image, image]);

  const clearImage = () => {
    setImage('');
    onUpdate({
      id: id,
      state: { id: id, name: name, image: '' }
    });
  }

  const coverStyle = {
    opacity: (image) ? '1' : '0',
    backgroundImage: (image) ? `url(${image})` : null
  };
  
  return (<div className="card scenario-card ph4 ph5-l pt3 flex flex-col items-center">
    <div className="scenario-cover z-1" style={coverStyle}></div>
    <div className="w-100 relative z-2">
      <label className="textfield textfield-white w-100">
        <input type="text" maxLength="96" value={name} onInput={nameHandler} onBlur={updateParentStateHandler} placeholder={placeholder} readOnly={readOnly} className="w-100" />
      </label>
    </div>
    <input onChange={uploadHandler} id={`img_upload_${id}`} style={{ display: 'none' }} type="file" accept="image/*"></input>
    {(image && !readOnly) && <button onClick={clearImage} type="button" className="scenario-del-img icon-btn btn-m">
      <span className="material-icons">clear</span>
    </button>}
    {!readOnly && <label  htmlFor={`img_upload_${id}`} className="scenario-img-label">
      <button type="button" className="icon-btn btn-primaryx btn-m">
        {!isLoading && <span className="material-icons">photo_camera</span>}
        {isLoading && <span className="material-icons rotate">sync</span>}
      </button>
    </label>}
    
  </div>);
}

export default ScenarioNameCard;