import { useEffect, useContext, useState } from "react";
import PageContext from "../context/page-context";
import FactorsDualSummary from "./FactorsDualSummary";

const DualSummary = (props) => {
  const { current, ord, project, state, factors, dictionary, type, compute_attr, onValid, readOnly } = props;
  const { onHelp } = useContext(PageContext);
  const [mounted, setMounted] = useState(false);

  let items;
  
  // scenarios transformation (selected + przewidywalnosc na niepewnosc)
  if (type === 'swot') {
    items = (factors[type] !== undefined) ? factors[type].map((el) => {
      return {...el, compare_attr: el.compare_attr2, compare_attr2: el.compare_attr }; // flip
    }) : [];
  } else {
    items = (factors[type] !== undefined) ? factors[type].map((el) => {
      return {...el, compare_attr2: 8 - el.compare_attr2 };
    }) : [];
  }
  
  // command
  const command = (dictionary.command !== undefined) ? dictionary.command.replace('{area}', project.area).replace('{time_horizon}', project['time_horizon']) : '';
  const attr1 = dictionary.attr1 ?? dictionary['uncertainty'];
  const attr2 = (dictionary.attr2 !== undefined) ? dictionary.attr2.replace('{time_horizon}', project['time_horizon']) : dictionary['importance'];

  // valid
  useEffect(() => {
    let valid;
    if (readOnly === true && current < ord) {
      valid = false; // uczen na ekranie grupowym czeka na zatwierdzenie nauczyciela
    } else if (current >= ord) {
      valid = true; // skip - krok juz zapisany
    } else {
      valid = true; // walidacja
    }
    onValid(valid);
  });
  
  // page help modal
  const  help = () => {
    onHelp({ html: dictionary.help ?? '' });
  };

  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    }, 140);
  });
  
  return (<main className="main-section flex w-100 items-center justify-center">
    <section className="step ph3 ph0-l">
      <div className="command w-80-l flex center mb6 mb7-l">
        <div className="f3" dangerouslySetInnerHTML={{ __html: command }}></div>
        {dictionary.help && <div className="pl4">
          <button className="icon-btn btn-primary btn-m" onClick={help}>
            <span className="material-icons">question_mark</span>
          </button>
        </div>}
      </div>
      {items && <FactorsDualSummary dictionary={dictionary} factors={items} state={state[type] ?? []} compute_attr={compute_attr} readOnly={readOnly} attr1={attr1} attr2={attr2} />}
      {dictionary.info !== undefined && <div className={`bottom-info flex animate-enter-down ${mounted ? 'animated' : ''}`}>
        <div><span className="material-icons mr2">info_outline</span></div>
        <div dangerouslySetInnerHTML={{ __html: dictionary.info }}></div>
      </div>}
    </section>
  </main>);
};

export default DualSummary;