import { Fragment, useState, useReducer, useEffect, useCallback, useContext, useRef } from "react"
// import { useParams } from 'react-router';
import { useLoaderData, useNavigate } from "react-router-dom"
import { SwitchTransition, CSSTransition } from "react-transition-group";

import PageContext from "../context/page-context";
import useHttp from "../hooks/use-http";
import { toPng } from 'html-to-image';

import ProjectHeader from "../components/ProjectHeader";
import ProjectFooter from "../components/ProjectFooter";
import ProjectHome from "../components/ProjectHome";
import ProjectSection from "../components/ProjectSection";
import ProjectQuestion from "../components/ProjectQuestion";
// import SelectFactorsGroup from "../components/SelectFactorsGroup";
import RateFactors from "../components/RateFactors";
import Choose from "../components/Choose";
import ChooseGroup from "../components/ChooseGroup";
import ChooseDouble from "../components/ChooseDouble";
import ChooseDoubleGroup from "../components/ChooseDoubleGroup";
import SwotSummary from "../components/SwotSummary";
import RateSummary from "../components/RateSummary";
import Chart from "../components/Chart";
import ChartSteepvl from "../components/ChartSteepvl";
import Report from "../components/Report";
import DualSummary from "../components/DualSummmary";
import ScenariosFactors from "../components/ScenariosFactors";
import ScenariosNames from "../components/ScenariosNames";
import ScenariosSelect from "../components/ScenariosSelect";
import Roadmap from "../components/Roadmap";
// import ProjectError from "../components/ProjectError";

// page state reducer
const pageReducer = (state, action) => {
  if (action.type === 'PAGE_CHANGE') {
    return { ...action.val }
  }
  if (action.type === 'SYNC_FACTORS') {
    if (action.val.ord === state.page.ord) {
      state.factors = action.val.factors;
      state.passed = action.val.passed;
    } else {
      console.log(action.val.ord + '/' + state.page.ord + ' ABORT');
    }
    return { ...state };
  }
  if (action.type === 'SYNC_FACTORS_STATE') {
    if (action.val.ord === state.page.ord) {
    state.factors = action.val.factors;
    state.state = action.val.state;
    state.current = action.val.current;
    } else {
      console.log(action.val.ord + '/' + state.page.ord + ' ABORT');
    }
    return { ...state };
  }
  if (action.type === 'UPDATE_STATE') {
    state.state[action.val.type] = action.val.state;
    return { ...state };
  }
  if (action.type === 'SET_STATE') {
    state.state = action.val;
    return { ...state };
  }
  if (action.type === 'SYNC_PROJECT') {
    if (action.val.ord === state.page.ord) {
      state.project = action.val.project;
      state.current = action.val.current;
    } else {
      console.log(action.val.ord + '/' + state.page.ord + ' ABORT');
    }
    return { ...state };
  }
  return {};
};

const ProjectPage = () => {
  // const pageData = useLoaderData();
  // const { project, page, dictionary, factors, state, current, id_user } = useLoaderData();
  const loaderData = useLoaderData();
  const [pageState, dispatchPage] = useReducer(pageReducer, loaderData);

  const { project, page, dictionary, factors, state, current, id_user, read_only, passed, show_unlock, screenshot, time_left } = pageState;
  const { factors_type, ord, template, compute_attr, add_symbols } = page;

  const [isValid, setIsValid] = useState(true);
  const [isUnlocked, setIsUnlocked] = useState(false);
  // const [isDisabled, setIsDisabled] = useState(false);

  const { onHelp, syncFrequency } = useContext(PageContext);
  const navigate = useNavigate();
  const { isLoading, error, sendRequest } = useHttp();

  const readOnly = (read_only === 1) ? true : false;
  
  // update pageState on page change
  useEffect(() => {
    dispatchPage({type: 'PAGE_CHANGE', val: loaderData});

    // update id user if new
    /* if (!idUser) {
      onSetIdUser(id_user);
    } */
  }, [loaderData]);
  
  // update current state by user
  const updateCurrentStateHandler = useCallback((data) => {
    dispatchPage({type: 'UPDATE_STATE', val: data});
    console.log(data);
    /* setCurrentState((prevState) => {
      return { ...prevState, [data.type]: data.state }
    }); */
  }, [dispatchPage]);

  // name the project
  const setCurrentStateHandler = useCallback((data) => {
    dispatchPage({type: 'SET_STATE', val: data});
    console.log(data);
  }, [dispatchPage]);

  // auto sync factors
  const syncPage = useCallback((data) => {
    if (data.state_refresh === 1) {
      if (data.factors) {
        dispatchPage({type: 'SYNC_FACTORS_STATE', val: { factors: data.factors, state: data.state, current: data.current, ord: data.page.ord }}); // uczniowie na ekranie nauczyciela
      }
    } else if (data.state_refresh === 2) {
      if (data.project) {
        dispatchPage({type: 'SYNC_PROJECT', val: { project: data.project, current: data.current, ord: data.page.ord }}); // project question
      }
    } else {
      if (data.factors) {
        dispatchPage({type: 'SYNC_FACTORS', val: { factors: data.factors, passed: data.passed, ord: data.page.ord }});
      }
    }
  }, [dispatchPage]);
  useEffect(() => {
    const syncInterval = setInterval(() => {
      sendRequest({
        url: window.location.pathname
      }, syncPage);
    }, syncFrequency);
    return () => clearInterval(syncInterval);
  }, [sendRequest, syncFrequency, syncPage]); 

  // update isValid
  const onValidHandler = useCallback((data) => {
    setIsValid(data);
  }, [setIsValid]);

  

  // create screenshot
  const chartRef = useRef(null);
  const takePictureHandler = () => {
    if (chartRef.current === null) {
      return Promise.resolve(true);
    }

    chartRef.current.classList.add('to-print');

    const url = window.location.pathname + '/upload-image';
    
    return toPng(chartRef.current, { cacheBust: false, })
      .then((dataUrl) => {
        const data = new FormData();
        data.append('image', dataUrl);
        data.append('image_type', page.stage);

        sendRequest({
          url: url,
          method: 'POST',
          body: data
        }, (response) => {
          console.log(response);
        });
      })
      .catch((err) => {
        console.log(err)
      });
  };

  // go to next page
  const goNextHandler = useCallback(() => {
    const nextUrl = '/' + project.slug + '/' + (parseInt(ord) + 1);
    navigate(nextUrl);
  }, [project.slug, ord, navigate]);

  // go to prev page
  const prevHandler = () => {
    const prevProjPage = parseInt(ord) - 1;
    if (prevProjPage >= 0) {
      const prevUrl = '/' + project.slug + '/' + prevProjPage;
      navigate(prevUrl);
    } else {
      navigate('/');
    }
  };

  // save do backend
  const saveHandler = useCallback(() => {
    const data = new FormData();
    data.append("json", JSON.stringify(state));
    
    sendRequest({
      url: window.location.pathname,
      method: 'POST',
      body: data
    }, (response) => {
      if (response) {
        // charts screenshots
        if (screenshot === 1) {
          console.log('start picture');
          takePictureHandler()
          .then(() => {
            goNextHandler();
          })
        } else {
          goNextHandler();
        }
      }
    });
  }, [sendRequest, state, goNextHandler, screenshot]);

  // refresh factors (on add factor)
  const factorsRefreshHandler = useCallback((factorData) => {
      sendRequest({
        url: window.location.pathname
      }, syncPage);
  }, [sendRequest]);
  
  // next button function
  const { id_owner } = project;
  const nextHandler = useCallback(() => {
    if (!isValid) {
      onHelp({ html: (readOnly) ? dictionary.wait : dictionary.invalid, icon: 'error' });
    } else if (readOnly && ord > current && parseInt(id_owner) === parseInt(id_user)) {
      saveHandler(); // save
      console.log('SAVE - teacher on individual screen');
    } else if (readOnly) {
      goNextHandler(); 
      console.log('SKIP');
    } else {
      saveHandler(); // save
      console.log('SAVE');
    }
  }, [isValid, readOnly, current, ord, dictionary.invalid, dictionary.wait, id_owner, id_user, onHelp, goNextHandler, saveHandler]);
  // (current >= ord)

  // unlock to edit
  const toggleUnlockedHandler = (data) => {
    
    // dispatchPage({type: 'SET_CURRENT', val: data});
    // setIsUnlocked(!isUnlocked);
    console.log(data);
  };

  const refs = {
    project_home: useRef(),
    project_question: useRef(null),
    project_section: useRef(null),
    choose: useRef(null),
    choose_group: useRef(null),
    rate_factors: useRef(null),
    choose_double: useRef(null),
    choose_double_group: useRef(null),
    swot_summary: useRef(null),
    rate_summary: useRef(null),
    chart: useRef(null),
    chart_steepvl: useRef(null),
    report: useRef(null),
    dual_summary: useRef(null),
    scenarios_factors: useRef(null),
    scenarios_names: useRef(null),
    scenarios_select: useRef(null),
    roadmap: useRef(null)
  };

  const nodeRef = refs[template];
  // console.log(time_left)
  return (<Fragment>
    <ProjectHeader project={project} ord={ord} dictionary={dictionary} stage={page.stage} />
    <SwitchTransition mode="out-in">
      <CSSTransition key={ord} nodeRef={nodeRef} addEndListener={(done) => {
              nodeRef.current.addEventListener("transitionend", done, false);
            }} classNames="fade" timeout={200}>
        <div ref={nodeRef} className="main">
      { template === 'project_home' && <ProjectHome current={current} ord={ord} project={project} dictionary={dictionary} onValid={onValidHandler} readOnly={readOnly} /> }
      { template === 'project_section' && <ProjectSection current={current} ord={ord} project={project} dictionary={dictionary} onValid={onValidHandler} readOnly={readOnly} /> }
      { template === 'project_question' && <ProjectQuestion current={current} ord={ord} project={project} dictionary={dictionary} onValid={onValidHandler} onUpdate={setCurrentStateHandler} readOnly={readOnly} /> }
      { template === 'choose' && <Choose current={current} ord={ord} project={project} dictionary={dictionary} factors={factors} type={factors_type} state={state} compute_attr={compute_attr} onUpdate={updateCurrentStateHandler} onValid={onValidHandler} onAddFactor={factorsRefreshHandler} readOnly={readOnly} addSymbols={add_symbols} /> }
      { template === 'choose_group' && <ChooseGroup current={current} ord={ord} project={project} dictionary={dictionary} factors={factors} type={factors_type} state={state} compute_attr={compute_attr} onUpdate={updateCurrentStateHandler} onValid={onValidHandler} readOnly={readOnly} /> }
      { template === 'rate_factors' && <RateFactors current={current} ord={ord} project={project} dictionary={dictionary} factors={factors} type={page.factors_type} state={state} compute_attr={compute_attr} onUpdate={updateCurrentStateHandler} onValid={onValidHandler} readOnly={readOnly} /> }
      { template === 'choose_double' && <ChooseDouble current={current} ord={ord} project={project} dictionary={dictionary} factors={factors} type={factors_type} state={state} compute_attr={compute_attr} onUpdate={updateCurrentStateHandler} onValid={onValidHandler} onAddFactor={factorsRefreshHandler} readOnly={readOnly} addSymbols={add_symbols} /> }
      { template === 'choose_double_group' && <ChooseDoubleGroup current={current} ord={ord} project={project} dictionary={dictionary} factors={factors} type={factors_type} state={state} compute_attr={compute_attr} onUpdate={updateCurrentStateHandler} onValid={onValidHandler} readOnly={readOnly} /> }
      { template === 'swot_summary' && <SwotSummary current={current} ord={ord} project={project} dictionary={dictionary} factors={factors} type={factors_type} state={state} onValid={onValidHandler} readOnly={readOnly} /> }
      { template === 'rate_summary' && <RateSummary current={current} ord={ord} project={project} dictionary={dictionary} factors={factors} type={factors_type} state={state} compare_attr={page.compare_attr} onValid={onValidHandler} readOnly={readOnly} /> }
      { template === 'chart' && <Chart ref={chartRef} current={current} ord={ord} project={project} dictionary={dictionary} factors={factors} type={factors_type} state={state} onValid={onValidHandler} readOnly={readOnly} /> }
      { template === 'chart_steepvl' && <ChartSteepvl ref={chartRef} current={current} ord={ord} project={project} dictionary={dictionary} factors={factors} type={factors_type} state={state} onValid={onValidHandler} readOnly={readOnly} /> }
      { template === 'report' && <Report project={project} dictionary={dictionary} factors={factors} type={factors_type} state={state} onValid={onValidHandler} readOnly={readOnly} /> }
      { template === 'dual_summary' && <DualSummary current={current} ord={ord} project={project} dictionary={dictionary} factors={factors} type={factors_type} state={state} compute_attr={compute_attr} onValid={onValidHandler} readOnly={readOnly} /> }
      { template === 'scenarios_factors' && <ScenariosFactors current={current} ord={ord} project={project} dictionary={dictionary} factors={factors} type={factors_type} state={state} compute_attr={compute_attr} onValid={onValidHandler} onUpdate={updateCurrentStateHandler} readOnly={readOnly} /> }
      { template === 'scenarios_names' && <ScenariosNames current={current} ord={ord} project={project} dictionary={dictionary} factors={factors} type={factors_type} state={state} compute_attr={compute_attr} onValid={onValidHandler} onUpdate={updateCurrentStateHandler} readOnly={readOnly} /> }
      { template === 'scenarios_select' && <ScenariosSelect ref={chartRef} current={current} ord={ord} project={project} dictionary={dictionary} factors={factors} type={factors_type} state={state} compute_attr={compute_attr} onValid={onValidHandler} onUpdate={updateCurrentStateHandler} readOnly={readOnly} /> }
      { template === 'roadmap' && <Roadmap project={project} dictionary={dictionary} factors={factors} type={factors_type} state={state} onUpdate={updateCurrentStateHandler} onValid={onValidHandler} readOnly={readOnly} /> }
        </div>
      </CSSTransition>
    </SwitchTransition>
    <ProjectFooter showUnlock={show_unlock} onNext={nextHandler} readOnly={readOnly} onPrev={prevHandler} onToggleUnlocked={toggleUnlockedHandler} valid={isValid} isUnlocked={isUnlocked} backLabel={dictionary.back} commandLabel={dictionary.next} dictionary={dictionary} passed={passed} passedTitle={dictionary.passed} lastOrd={project.steps} ord={ord} timeLeft={time_left} />
  </Fragment>);
}

export default ProjectPage;

// const { milestone, id_owner } = project;
// const [readOnly, setReadOnly] = useState(false);

// set readOnly
  /*useEffect(() => {
    if (id_user == id_owner) {
      if (ord <= current) {
        setReadOnly(true);
      } else {
        setReadOnly(false);
      }
    } else {
      if ((current && ord <= current) || state_source === 'project') { // || (milestone && ord < milestone)
        setReadOnly(true);
      } else {
        setReadOnly(false);
      }
    }
  }, [ord, current, id_owner, id_user]);*/