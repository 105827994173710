import { useState, useEffect } from "react";
import FactorProgressDual from "./FactorProgressDual";

const FactorsDualSummary = (props) => {
  const { type, factors, state, compute_attr, dictionary, onUpdate, readOnly, attr1, attr2 } = props;
  const [mounted, setMounted] = useState(false);

  const factorsLength = (factors) ? factors.length : 0;

  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    }, 140);
  });

  return (<div className={"card pt6 pb6 ph3x ph7-lx ph-card " + type}>
    <div className="half-cover z-1"></div>
    <div className='flex items-center justify-center dual-arrows'>
      <div className={`dual-arrow-left mr3 animate-enter-left ${mounted ? 'animated' : ''}`}>{attr1}</div>
      <div className={`dual-arrow ml3 animate-enter-right ${mounted ? 'animated' : ''}`}>{attr2}</div>
    </div>
    <div className="dual-scale flex mb3 fw5 onsurface-lighter">
      <div>7</div><div>6</div><div>5</div><div>4</div><div>3</div><div>2</div><div>1</div><div>1</div><div>2</div><div>3</div><div>4</div><div>5</div><div>6</div><div>7</div>
    </div>
    <div className={`scroll mh-scroll relative z-2 ${(factorsLength > 8) ? 'pl-2pr-l' : ''}`}>
      {factors && factors.map(item => <FactorProgressDual key={item.id} type={item.type} symbol={item.symbol} label={item.name} value={item.id} compute_attr={compute_attr} state={(state) ? state.find(el => el.id === item.id) : {}} compare_attr={item.compare_attr2} compare_attr2={item.compare_attr} readOnly={readOnly} />)}
    </div>
  </div>);
}

export default FactorsDualSummary;