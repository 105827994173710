import { useState, useEffect } from 'react';
import { DndContext, DragOverlay, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core'; // , DragOverlay

import Droppable from './Droppable';
import Draggable from './Draggable';

import FactorProgress from './FactorProgress';

const ChooseDrag = (props) => {
  const { tip, factors, state, compute_attr, type, dicFactor, readOnly } = props;
  const [mounted, setMounted] = useState(false);
  const [activeId, setActiveId] = useState(null); 

  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const sensors = useSensors(
    mouseSensor,
    touchSensor,
  );

  // progress
  const maxCompare_attr = (factors) ? Math.max(...factors.map(o => o.compare_attr)) : 0;
  if (factors) {
    factors.sort((a, b) => (a.compare_attr > b.compare_attr) ? -1 : 1);
  }

  // active factor for drag
  const activeFactor = (activeId) ? factors.find(el => el.id == activeId) : {};
  
  // symbols
  const symbols = {
    'social': 'S',
    'technological': 'T',
    'economic': 'EN',
    'ecological': 'EL',
    'political': 'P',
    'values': 'V',
    'legal': 'L'
  };

  const zoneSymbols = []
  zoneSymbols[1] = symbols[type] + 1;
  zoneSymbols[2] = symbols[type] + 2;
  zoneSymbols[3] = symbols[type] + 3;

  // selected factors
  const selected = [];
  selected[1] = state.find(el => el.symbol === zoneSymbols[1]);
  selected[2] = state.find(el => el.symbol === zoneSymbols[2]);
  selected[3] = state.find(el => el.symbol === zoneSymbols[3]);

  const selectedFactors = [];
  const unselectedFactors = [];
  for (let i = 0; i < factors.length; i++) {
    if (selected[1] && factors[i].id === selected[1].id) {
      selectedFactors[1] = factors[i];
    }
    else if (selected[2] && factors[i].id === selected[2].id) {
      selectedFactors[2] = factors[i];
    }
    else if (selected[3] && factors[i].id === selected[3].id) {
      selectedFactors[3] = factors[i];
    }
    else {
      unselectedFactors.push(factors[i]);
    }
  }

  // strefy drop
  const containers = [1, 2, 3];

  // items
  const updateStateParameterHandler = data => {
    const { id, zone } = data;
    
    const factorExistIndex = state.findIndex(el => el.id === id); // some
    let newState = [];
    
    if (factorExistIndex !== -1) {
      newState = state;
      if (zone === 'off') {

        newState = newState.filter(el => el.id !== id);
        
        /* delete newState[factorExistIndex];
        newState = newState.filter(n => n); // remove empty values */

        /* const oldAttr = newState[factorExistIndex][compute_attr];
        if (oldAttr && oldAttr === 1) {
          delete(newState[factorExistIndex][compute_attr]);
        } 
        const oldSymbol = newState[factorExistIndex].symbol;
        if (oldSymbol) {
          delete(newState[factorExistIndex]['symbol']);
        } */

      } else {
        newState[factorExistIndex][compute_attr] = 1;
        newState[factorExistIndex].symbol = symbols[type] + zone;
      }
    } else if (zone !== 'off') {
      newState = [].concat(state, [{ id: id, [compute_attr]: 1, symbol: symbols[type] + zone }]);
      // console.log({ id: id, [compute_attr]: 1, symbol: symbols[type] + zone })
    } else {
      newState = state;
    }

    props.onUpdate({
      type: props.type,
      state: newState
    });
  }

  function handleDragStart(event) {
    setActiveId(event.active.id);
  }

  const handleDragEnd = (event) => {
    const {over} = event;

    updateStateParameterHandler({ id: event.active.id, zone: (over ? over.id : null) });
    setActiveId(null);
  }

  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    }, 140);
  });

  return (<DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} sensors={sensors}>
    <div className="flex flex-wrap nh3">
      <div className="w-100 w-50-l pr1-l mb3 mb0-l">
        <div className={"card pt5 pt6-l pb5 pb6-l ph3 ph6-l " + type}>
          <div className={`flex justify-end animate-enter-up ${mounted ? 'animated' : ''}`}>
            <div className="dib relative w-tail mr2">
              <div className="tooltip-wrapper">
                <div className="tooltip">{ tip }</div>
              </div>
            </div>
          </div>
          <Droppable key="off" id="off">
            <div className="scroll h-scroll">
            {unselectedFactors && unselectedFactors.map(item => <Draggable key={item.id} id={item.id} readOnly={readOnly}><FactorProgress key={item.id} label={item.name} type={item.type} symbol={item.symbol} compare_attr={item.compare_attr} maxCompare_attr={maxCompare_attr} /></Draggable>)}
            </div>
          </Droppable>
        </div>
      </div>
      <div className="w-100 w-50-l pl6-l flex flex-column justify-evenly">
        {containers.map((id) => (
          <Droppable key={id} id={id} className="factor-dropzone flex items-center content-stretch">
            {selectedFactors[id] ? <Draggable key={selectedFactors[id].id} id={selectedFactors[id].id} readOnly={readOnly}><FactorProgress key={selectedFactors[id].id} label={selectedFactors[id].name} type={selectedFactors[id].type} id={selectedFactors[id].id} symbol={zoneSymbols[id]} compare_attr={selectedFactors[id].compare_attr} maxCompare_attr={maxCompare_attr} /></Draggable> : <div className='empty-drop'>{dicFactor + ' ' + zoneSymbols[id]}</div>}
          </Droppable>
        ))}
      </div>
    </div>

    <DragOverlay>
      {activeId ? (
        <div className="isdragged"><FactorProgress key={activeFactor.id} label={activeFactor.name} type={activeFactor.type} id={activeFactor.id} compare_attr={activeFactor.compare_attr} maxCompare_attr={maxCompare_attr} isDragged="1" /></div>
      ): null}
    </DragOverlay>
  </DndContext>);
}

export default ChooseDrag;
