// import RoadmapItem from "./components/RoadmapItem";

const RoadmapPeriod = (props) => {
  const { id, item, onEditPeriod, onEditSkill, onAddSkill, onEditAction, onAddAction, readOnly } = props;
  const { time_horizon, occupation, salary, skills, actions } = item;

  const editPeriodHandler = () => {
    if (!readOnly) {
      onEditPeriod(id);
    }
  };

  const editSkillHandler = (e) => {
    if (!readOnly) {
      onEditSkill(id, e.target.dataset.id);
    }
  };

  const addSkillHandler = () => {
    if (!readOnly) {
      onAddSkill(id);
    }
  };

  const editActionHandler = (e) => {
    if (!readOnly) {
      onEditAction(id, e.target.dataset.id);
    }
  };

  const addActionHandler = () => {
    if (!readOnly) {
      onAddAction(id);
    }
  };

  return (<div className={`flex flex-wrap w-100 roadmap-card pv3 mv2 ${(readOnly) ? 'readonly' : ''}`}>
    <div className="w-10"><div onClick={editPeriodHandler} className="wrap-horizon">{time_horizon ?? ''}</div></div>
    <div className="w-15"><div onClick={editPeriodHandler} className="wrap-occupation flex items-center">{occupation ?? ''}</div></div>
    <div className="w-15"><div onClick={editPeriodHandler} className="wrap-salary">{salary ?? ''}</div></div>
    <div className="w-30 ph1">
      {skills && skills.map((item, index) => <div key={index} onClick={editSkillHandler} data-id={index} className="item">{item}</div>)}
      {!readOnly && <button onClick={addSkillHandler} className="btn btn-left w-100 add-btn">
        <i className="material-icons mr2" aria-hidden="true">add</i>{props.dictionary['add_skill']}
      </button>}
    </div>
    <div className="w-30 pl1 pr3">
      {actions && actions.map((item, index) => <div key={index} onClick={editActionHandler} data-id={index} className="item">{item}</div>)}
      {!readOnly && <button onClick={addActionHandler} className="btn btn-left w-100 add-btn">
        <i className="material-icons mr2" aria-hidden="true">add</i>{props.dictionary['add_action']}
      </button>}
    </div>
  </div>)
}

export default RoadmapPeriod;