import { useEffect, useContext, useState } from "react";
import FactorsRateSummary from "./FactorsRateSummary";
import PageContext from "../context/page-context";

const RateSummary = (props) => {
  const { current, ord, state, factors, project, dictionary, type, compute_attr, compare_attr, onValid, readOnly } = props;
  const { onHelp } = useContext(PageContext);
  const helpText = dictionary.help ?? '';
  const [mounted, setMounted] = useState(false);

  // valid
  useEffect(() => {
    let valid;
    if (readOnly === true && current < ord) {
      valid = false; // uczen na ekranie grupowym czeka na zatwierdzenie nauczyciela
    } else if (current >= ord) {
      valid = true; // skip - krok juz zapisany
    } else {
      valid = true; // walidacja
    }
    onValid(valid);

    // const valid = (factors && type && factors[type].length > 0);
    // onValid(valid);
  });
  
  // command
  const command = (dictionary.command !== undefined) ? dictionary.command.replace('{time_horizon}', project.time_horizon) : '';

  // page help modal
  const  help = () => {
    onHelp({ html: helpText });
  };

  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    }, 140);
  });
  
  return (<main className="main-section flex w-100 items-center justify-center">
    <section className="step w-100 w-80-l ph3 ph0-l">
      <div className="command w-80-l flex center mb4 mb5-l">
        <div className="f3" dangerouslySetInnerHTML={{ __html: command }}></div>
        <div className="pl4">
          { helpText && <button onClick={help} className="icon-btn btn-primary btn-m">
            <span className="material-icons">question_mark</span>
          </button> }
        </div>
      </div>
      <FactorsRateSummary type={type} factors={factors[type] ?? []} state={state[type] ?? []} compute_attr={compute_attr} readOnly={readOnly} tip={dictionary['mean-score']} compare_attr={compare_attr} dictionary={dictionary} />
      {dictionary.info !== undefined && <div className={`bottom-info flex animate-enter-down ${mounted ? 'animated' : ''}`}>
        <div><span className="material-icons mr2">info_outline</span></div>
        <div dangerouslySetInnerHTML={{ __html: dictionary.info }}></div>
      </div>}
    </section>
  </main>);
}

export default RateSummary;