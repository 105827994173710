import { Link } from "react-router-dom";
import LangSwitch from "./LangSwitch";

const PageHeader = (props) => {
  return (<header className="flex justify-between items-center pa3 w-100 fixed">
    <Link to="/" className="icon-btn btn-tonal btn-l">
      <span className="material-icons">arrow_back</span>
    </Link>
    <div className="proj-title dib relative">
      <div className="proj-progress z-0"></div>
      <div className="flex items-center h-100 ph4 z-1 relative">
        <h1 className="f2 fw6">{ props.title }</h1>
      </div>
    </div>
    <div className="w3r">
      <LangSwitch />
    </div>
  </header>);
}

export default PageHeader;