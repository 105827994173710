import { Fragment } from "react"
import { useLoaderData, Link } from "react-router-dom"
import LangSwitch from "../components/LangSwitch";

const Home = () => {
  const pageData = useLoaderData();
  const { dictionary } = pageData;

  return (<Fragment>
    <header className="flex justify-end items-center pa3 w-100 fixed">
      <div className="w3r">
        <LangSwitch />
      </div>
    </header>
    <main className="main-section main-section-home flex flex-column w-100 items-center justify-between relative">
      <section className="section--start w-100">
        <div className="container-lg w-100 ph3 center relative z-2">
          <div className="flex items-center mb5">
              <img src="/images/eea_grants-300px.png" alt="" className="logo-eea mr4 mr5-l" />
              <img src="/images/forhesit_pl_300px.png" alt="" className="logo-forhesit" />
            </div>
        </div>
      </section>

      <section className="section--start w-100">
        <div className="container-lg w-100 ph3 center relative z-2">
          <div className="w-100 w-55-l">
            
            <h1 className="f-6 fw6 mt0 mb6">{ dictionary['create-the-future'] }</h1>
            <div className="content f4 fc-primary mb6 relative" dangerouslySetInnerHTML={{ __html: dictionary['invocation'] }}>
            
            </div>
            <ul className="list pl0 mv0 flex flex-column flex-row-l">
              <li className="mb3 mb0-l mr2-l">
                <Link to="/p/choose-individual-scenario" className="inline-flex items-center justify-center bg-secondary fc-on-primary fw5 ph3 pv3">
                  <i className="material-icons mr3" aria-hidden="true">person</i>
                  { dictionary['individual-work'] }
                </Link>
              </li>
              <li>
                <Link to="/p/choose-group-scenario" className="inline-flex items-center justify-center bg-primary fc-on-primary fw5 ph3 pv3">
                  <i className="material-icons mr3" aria-hidden="true">group</i>
                  { dictionary['group-work'] }
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="section--start w-100">
        <div className="container-lg w-100 pl3 pr3 pr7-l center relative z-2 pt5 pt6-l c-onsurface-light lh-copy f6">
        Projekt „System IT wspierający szkolnictwo wyższe i rozwój kariery z wykorzystaniem metodyki foresight (FORhesIT)” korzysta z dofinansowania o wartości 148.620,00 EUR otrzymanego w 85% od Islandii, Lichtensteinu i Norwegii w ramach Funduszy EOG oraz w 15% z budżetu państwa (nr projektu: EOG/21/K4/W/0118).
        </div>
      </section>

      <div className="dn db-l w-50 fixed bottom-0 right-0">
        <div className="start-img-wrapper relative">
          <img src="/images/start-img-1.svg" alt="" className="db w-100 h-auto" />
          <img src="/images/start-img-2.svg" alt="" className="db w-100 h-100 absolute top-0 left-0 layer" data-speed="-2" />
          <img src="/images/start-img-3.svg" alt="" className="db w-100 h-100 absolute top-0 left-0 layer" data-speed="2" />
          <img src="/images/start-img-4.svg" alt="" className="db w-100 h-100 absolute top-0 left-0 layer" data-speed="-1" />
          <img src="/images/start-img-5.svg" alt="" className="db w-100 h-100 absolute top-0 left-0 layer" data-speed="1" />
          <img src="/images/start-img-6.svg" alt="" className="db w-100 h-100 absolute top-0 left-0 layer" data-speed="-2" />
          <img src="/images/start-img-7.svg" alt="" className="db w-100 h-100 absolute top-0 left-0 layer" data-speed="-0.3" />
        </div>
      </div>
    </main>
  </Fragment>)
}

export default Home


// const apiUrl = 'http://forhesit.org/api'; // dev local
// const apiUrl = 'https://4futures.eu/api'; // dev on prod backend
const apiUrl = '/api'; // prod


// const apiUrl = 'https://fs.4futures.eu';
// const apiUrl = 'https://fs2.carteam.pl';

const loader = async ({request}) => {
// export async function loader({request}) {
  
  const lang = localStorage.getItem('lang') ?? 'pl';
  const id_user = localStorage.getItem('id_user') ?? '';

  const parsedUrl = new URL(request.url);
  const url = '/' + lang + parsedUrl.pathname;

  // dev
  const response = await fetch(apiUrl + url + '?id_user=' + id_user);
  // prod
  // const response = await fetch('https://fs2.carteam.pl' + url + '?id_user=' + id_user);

  if (!response.ok) {
    // ...
  } else {
    const resData = await response.json();

    return resData;
  }
};

export { loader, apiUrl };