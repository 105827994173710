import { useEffect, useCallback } from "react";
import FactorsCheckbox from "./FactorsCheckbox";

const Choose = (props) => {
  const { current, ord, project, state, factors, dictionary, type, compute_attr, onUpdate, onValid, onAddFactor, readOnly, addSymbols } = props;

  // update parent state
  const updateCurrentStateHandler = useCallback((data) => {
    onUpdate(data);
  }, [onUpdate]);
  
  // page validation
  useEffect(() => {
    let valid;
    if (current >= ord) {
      valid = true; // juz zapisane
    } else if (readOnly == true && current < ord) {
      valid = true; // nauczyciel bierny
    } else {
      valid = state[type] && state[type].length > 0 && state[type].length < 4;
    }
    onValid(valid);
  });

  // add new factor
  const addFactorHandler = () => {
    onAddFactor();
  };

  // task progress
  let taskProgress = false;
  let taskIcon = false;
  if (!readOnly) {
    taskProgress = state[type] ? state[type].length : 0;
    taskIcon = (state[type] && state[type].length > 0 && state[type].length < 4) ? 'task_alt' : 'block';
  }

  const command = (dictionary.command !== undefined) ? dictionary.command.replace('{area}', project.area) : '';
  
  return (<main className="main-section flex w-100 items-center justify-center">
    <section className="step ph3 ph0-l">
      <div className="command w-80-l flex center mb4 mb5-l">
        <div className="f3" dangerouslySetInnerHTML={{ __html: command }}></div>
      </div>
      <FactorsCheckbox onUpdate={updateCurrentStateHandler} onAddFactor={addFactorHandler} title={dictionary[type]} type={type} factors={factors[type]} state={state[type] ?? []} compute_attr={compute_attr} dictionary={dictionary} readOnly={readOnly} addSymbols={addSymbols} />
    </section>
    {taskProgress !== false && <div className="fixed bottom-1 right-1 proj-stage flex items-center h-100 ph4 z-1 bg-blur onsurface-light cursor-help">
      {taskProgress > 0 && <div className="material-icons mr2">{taskIcon}</div>}<div className="f5 fw5">{taskProgress}/3</div>
    </div>}
  </main>);
}

export default Choose;