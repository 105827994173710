import { Fragment } from "react"
import { useLoaderData } from "react-router-dom"
import ChooseIndividualScenario from '../components/ChooseIndividualScenario.js';
import ChooseGroupScenario from "../components/ChooseGroupScenario.js";

const Page = () => {
  const pageData = useLoaderData();
  const { page, dictionary } = pageData;
  
  return (<Fragment>
    { page.slug === 'choose-individual-scenario' && <ChooseIndividualScenario dictionary={dictionary} /> }
    { page.slug === 'choose-group-scenario' && <ChooseGroupScenario dictionary={dictionary} /> }
  </Fragment>)
}

export default Page;