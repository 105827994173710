const ScenarioSelectCard = (props) => {
  const { id, state, onUpdate, readOnly } = props;

  const name = state.name ?? '';
  const image = state.image ?? null;
  const active = state.selected === 1;

  const updateParentStateHandler = data => {
    if (!readOnly) {
      onUpdate({ id: id, checked: true });
    }
  };

  const coverStyle = {
    backgroundImage: (image) ? `url(${image})` : null
  };

  return (<div className="card scenario-card scenario-select choose-scenario ph4 ph5-l flex flex-col items-center">
    <input type="radio" id={"scenario" + id} name="scenario" checked={active} value="1" className="dn" onChange={updateParentStateHandler} />
    <div className={`scenario-cover z-1 ${image ? 'with-image' : ''}`} style={coverStyle}></div>
    <label htmlFor={"scenario" + id} className="w-100 f2 ff-title fw6 tc relative z-2 scenario-title ph4 ph5-l h-100 flex items-center justify-center pointer">
      <div className="dib over-wrap">{name}</div>
    </label>
  </div>);
}

export default ScenarioSelectCard;