import { useEffect, useState } from "react";
import { apiUrl } from '../pages/Home';

const Report = (props) => {
  const { dictionary, project } = props;
  const [lang, setLang] = useState(localStorage.getItem('lang') ?? 'pl');

  // const path = 'http://forhesit.org/';
  // const path = 'https://fs2.carteam.pl/';
  
  return (<main className="main-section flex w-100 items-center justify-center">

    <section className="w-100 section--two-cols section--final">

      <div className="container-lg w-100 ph3 center">
        <div className="flex flex-wrap items-center">
          <div className="col w-100 w-50-l">
            <div className="primary-container br-xl f3 pv6 ph7">
              <div className="flex items-center mb5">
                <img src="/images/icon-verified.svg" alt="" className="db mr3" />
                <h2 className="f1 fw6 mv0">{dictionary.title1}</h2>
              </div>
              <div dangerouslySetInnerHTML={{ __html: dictionary.content }}></div>
              {project.id_scenario != 1 && <ul className="list list--files flex flex-column pl0 mv0">
                <li>
                  <a href={apiUrl + '/' + lang + '/' + project.slug + '/report-word'} className="flex items-center justify-center surface br-md fw6 pv3 mv2">
                    <span className="material-icons mr3">download</span>
                    {dictionary.document} docx
                  </a>
                </li>
                <li>
                  <a href={apiUrl + '/' + lang + '/' + project.slug + '/report-pdf'} className="flex items-center justify-center surface br-md fw6 pv3 mv2">
                    <span className="material-icons mr3">download</span>
                    {dictionary.document} pdf
                  </a>
                </li>
              </ul>}
              {project.id_scenario == 1 && <ul className="list list--files flex flex-column pl0 mv0">
                <li>
                  <a href={apiUrl + '/' + lang + '/' + project.slug + '/report-word/career'} className="flex items-center justify-center surface br-md fw6 pv3 mv2">
                    <span className="material-icons mr3">download</span>
                    {dictionary.document} docx
                  </a>
                </li>
                <li>
                  <a href={apiUrl + '/' + lang + '/' + project.slug + '/report-pdf/career'} className="flex items-center justify-center surface br-md fw6 pv3 mv2">
                    <span className="material-icons mr3">download</span>
                    {dictionary.document} pdf
                  </a>
                </li>
              </ul>}
            </div>
          </div>
          <div className="col w-100 w-50-l">
            <div className="tertiary-container br-xl f4 pv6 ph7 relative">
              <div className="flex items-center mb7">
                <h2 className="f1 fw6 mv0 mr3">{dictionary.title2}</h2>
                <img src="/images/icon-trophy.svg" alt="" className="db ml-auto" />
              </div>
              <div dangerouslySetInnerHTML={{ __html: dictionary.content2 }}></div>
            </div>
          </div>
        </div>
      </div>

    </section>

  </main>);
}

export default Report;