import { useState, useContext } from 'react';
import FactorCheckbox from './FactorCheckbox';
import AddFactor from './AddFactor';
import PageContext from "../context/page-context";

const FactorsCheckbox = (props) => {
  const { factors, state, type, compute_attr, title, dictionary, readOnly, onAddFactor, addSymbols } = props;
  const [find, setFind] = useState('');
  const { unfold, onSetUnfold } = useContext(PageContext);

  // symbols
  const symbols = {
    'social': 'S',
    'technological': 'T',
    'economic': 'EN',
    'ecological': 'EL',
    'political': 'P',
    'values': 'V',
    'legal': 'L',
    'strengths': 'S',
    'weaknesses': 'W',
    'opportunities': 'O',
    'threats': 'T',
    'strengths_career': 'S',
    'weaknesses_career': 'W'
  };
  
  const updateStateParameterHandler = data => {
    const { id } = data;

    const factorExistIndex = state.findIndex(el => el.id === id); // some
    let newState = [];
    
    if (factorExistIndex !== -1) {
      if (compute_attr === 'indications') {
        newState = state.filter((obj) => obj.id !== id); // remove factor
      } else {
        newState = state;
        const oldAttr = newState[factorExistIndex][compute_attr];
        if (oldAttr && oldAttr === 1) {
          delete(newState[factorExistIndex][compute_attr]);
        } else {
          newState[factorExistIndex][compute_attr] = 1;
        }
      }
    } else {
      newState = [].concat(state, [{ id: id, [compute_attr]: 1 }]);
    }

    // add symbols
    if (addSymbols) {
      // const letter = type.charAt(0).toUpperCase();
      const letter = (type) ? symbols[type] : '';
      let counter = 1;
      for (let i = 0; i < newState.length; i++) {
        if (newState[i][compute_attr] === 1) {
          newState[i].symbol = letter + counter;
          counter++;
        } else {
          delete(newState[i].symbol);
        }
      }
    } 

    props.onUpdate({
      type: type,
      state: newState
    });
  }

  // filter factors
  let items;
  if (find) {
    items = factors.filter(({name}) => name.toLowerCase().includes(find.toLowerCase()));
  } else {
    items = factors;
  }
  const findHandler = (e) => {
    setFind(e.target.value);
  }
  const cancelFindHandler = () => {
    setFind('');
  }

  // on add new factor
  const onAddFactorHandler = (id) => {
    updateStateParameterHandler({ id: id });
    onAddFactor();
  };

  // unfold
  const onUnfoldHandler = () => {
    onSetUnfold(!unfold);
  }

  return (<div className={"card pt5 pt6-l pb4 ph3x ph7-l ph-card " + type}>
    {title && <h3 className="f2 fw6 mt0 mb3 ph2">{title}</h3>}
    <div className={`folding ${unfold ? "" : "scroll h-scroll"}`}>
      {items && items.map(item => <FactorCheckbox key={item.id} label={item.name} value={item.id} compute_attr={compute_attr} state={(state) ? state.find(el => el.id === item.id) : {}} onUpdate={updateStateParameterHandler} readOnly={readOnly} />)}
    </div>
    <div className="mt3 flex items-center justify-between">
      <div className='flex items-center'>
        <div className="find-input mr2">
          <input type="text" placeholder={dictionary.search} value={find} onChange={findHandler} className={find ? 'filled' : ''} />
          {!find && <button className="find-btn"><span className="material-icons">search</span></button>}
          {find && <button className="find-btn" onClick={cancelFindHandler}><span className="material-icons">close</span></button>}
        </div>
        <AddFactor type={type} dictionary={dictionary} onAddFactor={onAddFactorHandler} readOnly={readOnly} />
      </div>
      <div className='dn db-l'>
        <button onClick={onUnfoldHandler} className="matter-button-text with-leading-icon"><span className="material-icons">unfold_more</span>{unfold ? dictionary['fold'] : dictionary['unfold']}</button>
      </div>
    </div>
  </div>);
}

export default FactorsCheckbox;