import { useEffect, useContext } from "react";
import PageContext from "../context/page-context";
import FactorsProgress from "./FactorsProgress";

const SwotSummary = (props) => {
  const { current, ord, state, factors, project, dictionary, onValid, readOnly } = props;
  const { onHelp } = useContext(PageContext);

  const isCareer = factors.strengths_career != undefined;

  const factorsSignets = {
    strengths: 'S',
    weaknesses: 'W',
    opportunities: 'O',
    threats: 'T'
  };

  // command
  const command = dictionary.command.replace('{area}', project.area).replace('{scenario_name}', project.scenario_name);

  // update current state handler (component)
  // const { strengths, weaknesses, opportunities, threats } = state;
  
  /* useEffect((data) => {
    const s = (strengths) ? strengths.filter((el) => el.selected === 1).map(({ id, symbol }) => ({ id: id, symbol: symbol ?? '' })) : [];
    const w = (weaknesses) ? weaknesses.filter((el) => el.selected === 1).map(({ id, symbol }) => ({ id: id, symbol: symbol ?? '' })) : [];
    const o = (opportunities) ? opportunities.filter((el) => el.selected === 1).map(({ id, symbol }) => ({ id: id, symbol: symbol ?? '' })) : [];
    const t = (threats) ? threats.filter((el) => el.selected === 1).map(({ id, symbol }) => ({ id: id, symbol: symbol ?? '' })) : [];
    const swot = s.concat(w, o, t);
    onUpdate({ type: 'swot', state: swot });
    // const valid = s.length > 0 && w.length > 0 && o.length > 0 && t.length > 0
    // onValid(valid);
  }, []); */

  // valid
  useEffect(() => {
    let valid;
    if (readOnly === true && current < ord) {
      valid = false; // uczen na ekranie grupowym czeka na zatwierdzenie nauczyciela
    } else if (current >= ord) {
      valid = true; // skip - krok juz zapisany
    } else {
      valid = true; // moze jednak zliczyc: valid = s.length > 0 && w.length > 0 && o.length > 0 && t.length > 0
    }
    onValid(valid);
  });

  // filter only selected factors
  const fsCareer = (isCareer && factors !== undefined && factors.strengths_career !== undefined) ? factors.strengths_career.filter((el) => el.compare_attr === 1) : [];
  const fwCareer = (isCareer && factors !== undefined && factors.weaknesses_career !== undefined) ? factors.weaknesses_career.filter((el) => el.compare_attr === 1) : [];

  const fs = (factors !== undefined && factors.strengths !== undefined) ? factors.strengths.filter((el) => el.compare_attr === 1) : [];
  const fw = (factors !== undefined && factors.weaknesses !== undefined) ? factors.weaknesses.filter((el) => el.compare_attr === 1) : [];
  const fo = (factors !== undefined && factors.opportunities !== undefined) ? factors.opportunities.filter((el) => el.compare_attr === 1) : [];
  const ft = (factors !== undefined && factors.threats !== undefined) ? factors.threats.filter((el) => el.compare_attr === 1) : [];

  // page help modal
  const  help = () => {
    onHelp({ html: dictionary.help });
  };
  
  return (<main className="main-section flex w-100 items-center justify-center">

    <section className="step wide w-100 w-80-l ph3 ph0-l">
      <div className="command w-80-l flex center mb5">
        <div className="f3" dangerouslySetInnerHTML={{ __html: command }}></div>
        { dictionary.help && <div className="pl4">
          <button className="icon-btn btn-primary btn-m" onClick={help}>
            <span className="material-icons">question_mark</span>
          </button>
        </div>}
      </div>
      
      {isCareer && <div>
        <div className="flex flex-wrap mb2">
          <div className="w-100 w-50-l pr1-l mb3 mb0-l">
            <FactorsProgress type="strengths_career" factors={fsCareer} title={dictionary['strengths_career']} signet={factorsSignets.strengths} signetPosition="left"  />
          </div>
          <div className="w-100 w-50-l pl1-l">
            <FactorsProgress type="weaknesses_career" factors={fwCareer} title={dictionary['weaknesses_career']} signet={factorsSignets.weaknesses} signetPosition="right"  />
          </div>
        </div>
      </div>}

      {!isCareer && <div>
        <div className="flex flex-wrap mb2">
          <div className="w-100 w-50-l pr1-l mb3 mb0-l">
            <FactorsProgress type="strengths" factors={fs} title={dictionary['strengths']} signet={factorsSignets.strengths} signetPosition="left"  />
          </div>
          <div className="w-100 w-50-l pl1-l">
            <FactorsProgress type="weaknesses" factors={fw} title={dictionary['weaknesses']} signet={factorsSignets.weaknesses} signetPosition="right"  />
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="w-100 w-50-l pr1-l mb3 mb0-l">
            <FactorsProgress type="opportunities" factors={fo} title={dictionary['opportunities']} signet={factorsSignets.opportunities} signetPosition="left"  />
          </div>
          <div className="w-100 w-50-l pl1-l">
            <FactorsProgress type="threats" factors={ft} title={dictionary['threats']} signet={factorsSignets.threats} signetPosition="right"  />
          </div>
        </div>
      </div>}

    </section>
    
  </main>);
}

export default SwotSummary;