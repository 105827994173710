import { useEffect, useState } from "react";

const ProjectQuestion = (props) => {
  const { current, ord, dictionary, onValid, onUpdate, project, readOnly } = props;
  const [area, setArea] = useState((project.area) ? project.area : (dictionary.area ?? ''));
  const [question, setQuestion] = useState(project.question ?? '');
  const [timeHorizon, setTimeHorizon] = useState(project['time_horizon'] ?? '');
  const [timeLimit, setTimeLimit] = useState(project['time_limit'] ?? 0);
  
  const areaHandler = (event) => {
    setArea(event.target.value);
  };
  
  const questionHandler = (event) => {
    setQuestion(event.target.value);
  };
  
  const horizonHandler = (event) => {
    setTimeHorizon(event.target.value);
  };

  // update parent state
  const updateCurrentStateHandler = () => {
    if (question && timeHorizon) {
      const data = {
        question: question,
        horizon: timeHorizon,
        area: area,
        time_limit: timeLimit
      };
  
      onUpdate(data);
    }
  };

  const limitHandler = (event) => {
    setTimeLimit(event.target.value);
  };

  // update readOnly users state
  useEffect(() => {
    if (readOnly && project.question != question) {
      setArea(project.area);
      setQuestion(project.question);
      setTimeHorizon(project['time_horizon']);
      setTimeLimit(project['time_limit']);
    }
  }, [readOnly, project.question, project['time_horizon'], project['time_limit']]);

  // valid
  useEffect(() => {
    let valid;
    if (readOnly === true && current < ord) {
      valid = false; // uczen na ekranie grupowym czeka na zatwierdzenie nauczyciela
    } else if (current >= ord) {
      valid = true; // skip - krok juz zapisany
    } else {
      valid = ((question && timeHorizon)) ? true : false; // walidacja
    }
    onValid(valid);
  }, [question, timeHorizon, current]);

  return (<main className="main-section flex w-100 items-center justify-center">
    <section className="w-100">

      <div className="container-lg w-100 ph3 center">
        <div className="new-project secondary br-xl pa7 relative overflow-hidden">
          {(project.id_work_mode == 2) && <div className="absolute top-0 right-0 pt7 pr7 flex flex-column items-center mb2">
            <label className="form__label dib f3 fw5 mb3">{dictionary['time_limit']}</label>
            <select value={timeLimit} onChange={limitHandler} onBlur={updateCurrentStateHandler} disabled={readOnly} className="form-select form__field surface br-sm ph3 select-secondaryx">
              <option value="0">{dictionary['unlimited']}</option>
              <option value="120">2 min.</option>
              <option value="180">3 min.</option>
              <option value="300">5 min.</option>
            </select>
          </div>}
          <h2 className="f-6 fw6 mt0 mb5">{dictionary.title}</h2>
          <form action="" className="w-100 w-60-l" autoComplete="off">
            {(project.id_scenario === 5 || project.id_scenario === 3) && <div className="mb5">
              <label className="form__label dib f3 fw5 mb3">{dictionary['label0']}</label>
              <input onInput={areaHandler} onBlur={updateCurrentStateHandler} type="text" name="area" id="area" value={area} readOnly={readOnly} className="form__field db w-100 surface br-sm ph3" placeholder={dictionary['placeholder0']} />
            </div>}
            {(project.id_scenario !== 5 && project.id_scenario !== 3) && <input type="hidden" name="area" id="area" value={area} />}
            <div className="mb5">
              <label className="form__label dib f3 fw5 mb3">{dictionary['label1']} {area}</label>
              <input onInput={questionHandler} onBlur={updateCurrentStateHandler} type="text" name="question" id="question" value={question} readOnly={readOnly} className="form__field db w-100 surface br-sm ph3" placeholder={dictionary['placeholder1']} />
              <p className="form__tips f6 pl3 mt3 mb0" dangerouslySetInnerHTML={{ __html: dictionary['hint'] }}></p>
            </div>
            <div>
              <label className="form__label dib f3 fw5 mb3">{dictionary['label2']}</label>
              <input onInput={horizonHandler} onBlur={updateCurrentStateHandler} type="text" name="time_horizon" id="time_horizon" value={timeHorizon} readOnly={readOnly} className="form__field db w-100 surface br-sm ph3" placeholder={dictionary['placeholder2']} list="years" autoComplete="off" />
              <datalist id="years">
                <option value="2025">2025</option>
                <option value="2030">2030</option>
                <option value="2035">2035</option>
                <option value="2040">2040</option>
                <option value="2045">2045</option>
                <option value="2050">2050</option>
                <option value="2055">2055</option>
              </datalist>
            </div>
          </form>

          <div className="dn db-l img-wrapper absolute bottom-0">
            <img src="/images/new-project.svg" alt="" className="db mw-100 ml-auto" />
          </div>
        </div>
      </div>

    </section>
  </main>);
}

export default ProjectQuestion;