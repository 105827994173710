import { useEffect, useState, useContext, useCallback } from "react";
import PageContext from "../context/page-context";
import RoadmapPeriod from "./RoadmapPeriod";
import RoadmapPeriodEdit from "./RoadmapPeriodEdit";
import RoadmapSkillEdit from "./RoadmapSkillEdit";
import RoadmapActionEdit from "./RoadmapActionEdit";

/* const careerReducer = (state, action) => {
  if (action.type === 'UPDATE_STATE') {
    state.state[action.val.type] = action.val.state;
    return { ...state };
  }
}; */

const Roadmap = (props) => {
  const { current, ord, project, state, dictionary, onUpdate, readOnly, onValid } = props;
  // const [careerState, dispatchCareer] = useReducer(careerReducer, state.career ?? {});
  const [goal, setGoal] = useState((state.career !== undefined && state.career.goal !== undefined) ? state.career.goal : '');
  const [editedPeriod, setEditedPeriod] = useState(null);
  const [editedSkill, setEditedSkill] = useState(null);
  const [editedAction, setEditedAction] = useState(null);
  const { onHelp } = useContext(PageContext);
  
  const stateCareer = (state.career !== undefined && state.career.roadmap !== undefined) ? state.career : {
    goal: '',
    roadmap: [
    ]
  };

  /* const stateCareer = {
    goal: '',
    roadmap: [
      {
        time_horizon: '2023-2025',
        occupation: 'Sales manager',
        salary: '5000 zł',
        skills: [
          'Nic nie muszę',
          'Dupa',
          'Cipa'
        ],
        actions: [
          'xxx',
          'xxx'
        ]
      },
      {
        time_horizon: '2025-2027',
        occupation: 'Sales king',
        salary: '55000 zł',
        skills: [
          'xxx',
          'xxx',
          'xxx'
        ],
        actions: [
          'xxx',
          'xxx'
        ]
      }
    ]
  }; */

  const roadmap = stateCareer.roadmap;
  const roadmapLength = roadmap.length;

   // update parent state
   const updateCurrentStateHandler = useCallback((data) => {
    // onUpdate(data);
    onUpdate({
      type: 'career',
      state: data
    });
  }, [onUpdate]);

  const goalInputHandler = (e) => {
    setGoal(e.target.value);
  };

  const goalBlurHander = (e) => {
    stateCareer.goal = e.target.value;
    updateCurrentStateHandler(stateCareer);
  };

  // edit period
  const addPeriodHandler = () => {
    if (goal === '') {
      onHelp({ html: dictionary['empty_goal'] });
    } else {
      setEditedPeriod({
        id: null,
        data: {
          time_horizon: '',
          occupation: '',
          salary: ''
        }
      });
    }
  };

  const editPeriodHandler = (id) => {
    setEditedPeriod({
      id: id,
      data: roadmap[id]
    });
  }

  const deletePeriodHandler = (id) => {
    delete stateCareer.roadmap[id];
    stateCareer.roadmap = stateCareer.roadmap.filter((el) => el != null);
    updateCurrentStateHandler(stateCareer);
  }

  const savePeriodHandler = ({id, data}) => {
    if (id !== null) {
      stateCareer.roadmap[id].time_horizon = data.time_horizon;
      stateCareer.roadmap[id].occupation = data.occupation;
      stateCareer.roadmap[id].salary = data.salary;
    } else {
      stateCareer.roadmap.push(data);
    }
    updateCurrentStateHandler(stateCareer);
  }

  // edit skill
  const addSkillHandler = (idPeriod) => {
    setEditedSkill({
      idPeriod: idPeriod,
      id: null,
      data: ''
    });
  };

  const editSkillHandler = (idPeriod, id) => {
    setEditedSkill({
      idPeriod: idPeriod,
      id: id,
      data: stateCareer.roadmap[idPeriod].skills[id]
    });
  }

  const deleteSkillHandler = (idPeriod, id) => {
    delete stateCareer.roadmap[idPeriod].skills[id];
    stateCareer.roadmap[idPeriod].skills = stateCareer.roadmap[idPeriod].skills.filter((el) => el != null);
    updateCurrentStateHandler(stateCareer);
  };

  const saveSkillHandler = ({idPeriod, id, data}) => {
    if (id !== null) {
      stateCareer.roadmap[idPeriod].skills[id] = data;
    } else {
      if (stateCareer.roadmap[idPeriod].skills === undefined) {
        stateCareer.roadmap[idPeriod].skills = [];
      }
      stateCareer.roadmap[idPeriod].skills.push(data);
    }
    updateCurrentStateHandler(stateCareer);
  };

  // edit action
  const addActionHandler = (idPeriod) => {
    setEditedAction({
      idPeriod: idPeriod,
      id: null,
      data: ''
    });
  };

  const editActionHandler = (idPeriod, id) => {
    setEditedAction({
      idPeriod: idPeriod,
      id: id,
      data: stateCareer.roadmap[idPeriod].actions[id]
    });
  }

  const deleteActionHandler = (idPeriod, id) => {
    delete stateCareer.roadmap[idPeriod].actions[id];
    stateCareer.roadmap[idPeriod].actions = stateCareer.roadmap[idPeriod].actions.filter((el) => el != null);
    updateCurrentStateHandler(stateCareer);
  };

  const saveActionHandler = ({idPeriod, id, data}) => {
    if (id !== null) {
      stateCareer.roadmap[idPeriod].actions[id] = data;
    } else {
      if (stateCareer.roadmap[idPeriod].actions === undefined) {
        stateCareer.roadmap[idPeriod].actions = [];
      }
      stateCareer.roadmap[idPeriod].actions.push(data);
    }
    updateCurrentStateHandler(stateCareer);
  };
  

  const hideDialogPeriodHandler = () => {
    /* hidePeriodDialogHandler(); */
  };

  // page help modal
  const  help = () => {
    onHelp({ 
      html: '<img src="/images/roadmap.png" alt="" width="945" height="852" />',
      width: 1000,
      imageUrl: '/images/roadmap.png',
      imageWidth: 945,
      imageHeight: 852
     });
  };

 
  
  return (<main className="main-section flex w-100 items-center justify-center">
    <section className="stepx step-roadmap ph3 ph0-l">
      <div className="command w-80-l flex center mb4 mb6-l">
        <div className="f3" dangerouslySetInnerHTML={{ __html: dictionary.command }}></div>
        <div className="pl4">
          <button className="icon-btn btn-primary btn-m" onClick={help}>
            <span className="material-icons">question_mark</span>
          </button>
        </div>
      </div>

      <div className="roadmap-goal pa5 w-100 center mb4 mb6-l flex flex-column flex-row-l flex-wrap-l">
        <label className="f2 fw5 roadmap-label flex items-center justify-center-l w-20-l">{dictionary.goal}</label>
        <div className="w-80-l">
          <input type="text" name="goal" value={goal} onInput={goalInputHandler} onBlur={goalBlurHander} readOnly={readOnly} className={`form__field db w-100 surface br-sm ph3 goal-name f4 fw5 ${(goal === '') ? 'empty-goal' : ''}`} placeholder={dictionary['placeholder']} />
          <p className="form__tips f5 mt3 mb0 goal-hint" dangerouslySetInnerHTML={{ __html: dictionary['hint'] }}></p>
        </div>
      </div>

      {roadmapLength > 0 && <div className="roadmap min-w">
        <div className="flex flex-wrap w-100">
          <div className="w-10"><div className="label pa3 justify-between"><h5>{dictionary['horizon_label']}</h5><h3>{dictionary['horizon_title']}</h3></div></div>
          <div className="w-15"><div className="label pa3 justify-between"><h5>{dictionary['occupation_label']}</h5><h3>{dictionary['occupation_title']}</h3></div></div>
          <div className="w-15"><div className="label pa3 justify-between"><h5>{dictionary['salary_label']}</h5><h3>{dictionary['salary_title']}</h3></div></div>
          <div className="w-30"><div className="label pa3 justify-between"><h5>{dictionary['skills_label']}</h5><h3>{dictionary['skills_title']}</h3></div></div>
          <div className="w-30"><div className="label pa3 justify-between"><h5>{dictionary['actions_label']}</h5><h3>{dictionary['actions_title']}</h3></div></div>
        </div>
        {roadmap && roadmap.map((item, index) => <RoadmapPeriod key={index} id={index} item={item} dictionary={dictionary} onEditPeriod={editPeriodHandler} onEditSkill={editSkillHandler} onAddSkill={addSkillHandler} onEditAction={editActionHandler} onAddAction={addActionHandler} readOnly={readOnly} />)}
        {!readOnly && <button onClick={addPeriodHandler} className="btn btn-l btn-roadmapx btn-primary w-100"><i className="material-icons mr2" aria-hidden="true">add</i>Dodaj nowy etap kariery</button>}
      </div>}
      {roadmapLength === 0 && <div>
        <button onClick={addPeriodHandler} className="btn btn-l btn-roadmapx btn-primary w-100"><i className="material-icons mr2" aria-hidden="true">add</i>{dictionary['first_step']}</button>
      </div>}
      
    </section>
    <RoadmapPeriodEdit dictionary={dictionary} item={editedPeriod} onPeriodSave={savePeriodHandler} onDelete={deletePeriodHandler} />
    <RoadmapSkillEdit dictionary={dictionary} item={editedSkill} onSave={saveSkillHandler} onDelete={deleteSkillHandler} />
    <RoadmapActionEdit dictionary={dictionary} item={editedAction} onSave={saveActionHandler} onDelete={deleteActionHandler} />
  </main>);
} 

export default Roadmap;