import { useEffect, forwardRef } from "react";
import ScenarioSelectCard from "./ScenarioSelectCard";

const ScenariosSelect = forwardRef((props, ref) => {
  const { current, ord, state, factors, dictionary, type, compute_attr, onUpdate, onValid, readOnly } = props;
  // const [factorsSelected, setFactorsSelected] = useState(factors[type].filter((el) => el.compare_attr === 1));

  const scenariosIds = [1, 2, 3, 4];

  // state
  const items = state.scenarios ?? [];

  // selected factors
  const factorsSelected = factors[type];

  // on factors change
  /* useEffect(() => {
    setFactorsSelected(factors[type].filter((el) => el.compare_attr === 1));
  }, [factors, type]); */

  // page validation
  useEffect(() => {
    let valid;
    if (readOnly === true && current < ord) {
      valid = false; // uczen na ekranie grupowym czeka na zatwierdzenie nauczyciela
    } else if (current >= ord) {
      valid = true; // skip - krok juz zapisany
    } else {
      valid = items.some((el) => el[compute_attr] === 1);
    }
    onValid(valid);
  });

  // update page state
  const updateCurrentStateHandler = (data) => {
    const newState = items;
    const factorExistIndex = newState.findIndex(el => el.id === data.id);

    // unselect all scenarios
    newState.map(el => delete(el[compute_attr]));

    // select scenario
    newState[factorExistIndex][compute_attr] = 1;
    
    onUpdate({
      type: 'scenarios',
      state: newState
    });
  };

  return (<main className="main-section flex w-100 items-center justify-center">
    <div className="w-100 flex flex-column justify-center items-center">
      <section className="step wide w-100 w-80-l ph3 ph0-l step-error">
        <div className="command w-80-l flex center mb4 mb4-l">
          <div className="f3" dangerouslySetInnerHTML={{ __html: dictionary.command }}></div>
        </div>
      </section>

      <section ref={ref} className="scenarios w-100 w-90-l max-wide ph3 ph0-l relative">
        <div className="flex justify-center mb2">
          <div className={`label-y center ${factorsSelected[1].type}`}>
            <div className="onsurface-light">{dictionary['high-level']}</div> 
            {factorsSelected[1] && <strong>{factorsSelected[1].name}</strong>}
          </div>
        </div>

        <div className="relative mh3">
          <div className="max-step center relative">
            <div className="grid grid-cols-2 gap-8">
            {scenariosIds.map(item => <ScenarioSelectCard key={item} id={item} state={items.find(el => el && el.id === item) ?? {}} pholder={dictionary['s-name']} onUpdate={updateCurrentStateHandler} readOnly={readOnly} />)}
              <div className="scenario-x-axis flex items-center">
                <div className="x-arrows"></div>
              </div>
              <div className="scenario-y-axis flex justify-center">
                <div className="y-arrows"></div>
              </div>
            </div>
          </div>
        </div>

        <div className={`label-x label-left center ${factorsSelected[0].type}`}>
          <div className="onsurface-light">{dictionary['low-level']}</div> 
          {factorsSelected[0] && <strong>{factorsSelected[0].name}</strong>}
        </div>
        <div className={`label-x label-right center ${factorsSelected[0].type}`}>
          <div className="onsurface-light">{dictionary['high-level']}</div> 
          {factorsSelected[0] && <strong>{factorsSelected[0].name}</strong>}
        </div>

        <div className="flex justify-center mt2">
          <div className={`label-y center ${factorsSelected[1].type}`}>
            <div className="onsurface-light">{dictionary['low-level']}</div> 
            {factorsSelected[1] && <strong>{factorsSelected[1].name}</strong>}
          </div>
        </div>
      </section>
    </div>
  </main>);
});

export default ScenariosSelect;