import { useState, useCallback } from 'react';
import { apiUrl } from '../pages/Home';

const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = useCallback(async (requestConfig, applyData) => {
    setIsLoading(true);
    setError(null);

    const lang = localStorage.getItem('lang') ?? 'pl';
    const id_user = localStorage.getItem('id_user') ?? '';
    
    // dev
    // const fullUrl = 'http://forhesit.org/' + lang + requestConfig.url + '?id_user=' + id_user
    // production
    // const fullUrl = 'https://fs2.carteam.pl/' + lang + requestConfig.url + '?id_user=' + id_user
    const fullUrl = apiUrl + '/' + lang + requestConfig.url + '?id_user=' + id_user

    try {
      const response = await fetch(fullUrl, {
        method: requestConfig.method ? requestConfig.method : 'GET',
        headers: requestConfig.headers ? requestConfig.headers : {},
        body: requestConfig.body ? requestConfig.body : null
      });
      // body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,

      if (!response.ok) {
        throw new Error('Request failed!');
      }

      const data = await response.json();
      
      applyData(data);
    } catch (err) {
      setError(err.message || 'Something went wrong!');
    }
    setIsLoading(false);
  }, []);

  return {
    isLoading,
    error,
    sendRequest,
  };
};

export default useHttp;

// https://www.smashingmagazine.com/2020/07/custom-react-hook-fetch-cache-data/