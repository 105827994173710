import { useEffect, useCallback, useContext } from "react";
import PageContext from "../context/page-context";
import FactorsCheckboxDual from "./FactorsCheckboxDual";

const ScenariosFactors = (props) => {
  const { current, ord, project, state, factors, dictionary, type, compute_attr, onUpdate, onValid, readOnly } = props;
  const { onHelp } = useContext(PageContext);
  
  // przewidywalnosc na niepewnosc
  const items = (factors[type] !== undefined) ? factors[type].map((el) => {
    return {...el, compare_attr2: 8 - el.compare_attr2 };
  }) : [];

  const x = 'compare_attr';
  const y = 'compare_attr2';

  // y dash (x0)
  const itemsCount = items.length;
  const xSum = items.reduce((acc, el) => { return acc + el[x]; }, 0);
  const x0 = (xSum > 0) ? xSum / itemsCount : 1;
  
  // x dash
  const ySum = items.reduce((acc, el) => { return acc + el[y]; }, 0);
  const y0 = (ySum > 0) ? ySum / itemsCount : 1; 
  
  // factors meeting the requirements
  let itemsOk = items.filter((el) => el.compare_attr > x0 && el.compare_attr2 > y0);
  let itemsOkCount = itemsOk.length;

  // if factors < 2
  if (itemsCount > 0 && itemsOkCount < 2) {
    let py0;
    let px0;

    if (x0 > y0) {
      py0 = 7;
      px0 = 7 + x0 - y0;
    } else {
      px0 = 7;
      py0 = 7 + y0 - x0;
    }

    // calculate r
    const itemsR = items.map((el) => {
      return {...el, r: Math.sqrt(Math.pow(px0 - el.compare_attr, 2) + Math.pow(py0 - el.compare_attr2, 2)) };
    });

    itemsR.sort((a, b) => (a.r < b.r) ? -1 : 1);

    // get all factors with r1
    const r1 = itemsR[0].r; // r value of 2-nd element
    const place1factors = itemsR.filter((el) => el.r === r1);

    itemsOk = [...place1factors];
    itemsOkCount = itemsOk.length;

    // add factors with r2
    if (itemsOkCount < 2) {
      const r2 = itemsR[1].r; // r value of 2-nd element
      const place2factors = itemsR.filter((el) => el.r === r2);

      itemsOk = [...itemsOk, ...place2factors];
    }

    itemsOkCount = itemsOk.length;
  }

  let command;
  if (itemsOkCount >= 2) {
    command = dictionary.command.replace('{area}', project.area);
  } else {
    command = dictionary.command3;
  }
  /* else if (itemsOkCount == 2) {
    command = dictionary.command2.replace('{area}', project.area);
  } */

  // update parent state
  const updateCurrentStateHandler = useCallback((data) => {
    onUpdate(data);
  }, [onUpdate]);
  
  // auto check if two factors
  /* useEffect(() => {
    if (!readOnly && itemsOkCount === 2) {
      const newState = state[type];
      const itemsOkIds = itemsOk.map(el => el.id);
      
       for (let i = 0; i < newState.length; i++) {
        if (itemsOkIds.includes(newState[i].id)) {
          newState[i][compute_attr] = 1;
        } else {
          delete(newState[i][compute_attr]);
        }
      }
      
      updateCurrentStateHandler({
        type: type,
        state: [...newState]
      }); 
    }
  }, []);*/

  // page validation
  useEffect(() => {
    let valid;
    if (readOnly === true && current < ord) {
      valid = false; // uczen na ekranie grupowym czeka na zatwierdzenie nauczyciela
    } else if (current >= ord) {
      valid = true; // skip - krok juz zapisany
    } else {
      const selectedFactors = (state[type]) ? state[type].filter((obj) => obj[compute_attr] === 1).length : 0;
      valid = (selectedFactors === 2);
    }
    onValid(valid);
  });

  // page help modal
  const  help = () => {
    onHelp({ html: dictionary.help });
  };
  
  return (<main className="main-section flex w-100 items-center justify-center">
    <section className="step ph3 ph0-l">
      <div className="command w-80-l flex center mb6 mb7-l">
        <div className="f3" dangerouslySetInnerHTML={{ __html: command }}></div>
        {dictionary.help && <div className="pl4">
          <button className="icon-btn btn-primary btn-m" onClick={help}>
            <span className="material-icons">question_mark</span>
          </button>
        </div>}
      </div>
      {itemsOkCount > 0 && <FactorsCheckboxDual onUpdate={updateCurrentStateHandler} dictionary={dictionary} type={type} factors={itemsOk} state={state[type] ?? []} compute_attr={compute_attr} readOnly={readOnly} />}
    </section>
  </main>);
}

export default ScenariosFactors;