import { useEffect, useCallback, useContext } from "react";
import FactorsCheckboxGroup from "./FactorsCheckboxGroup";
import PageContext from "../context/page-context";

const ChooseDoubleGroup = (props) => {
  const { current, ord, state, factors, project, dictionary, type, compute_attr, onUpdate, onValid, readOnly } = props;
  const { onHelp } = useContext(PageContext);

  const types = type.split(',');
  const type1 = types[0];
  const type2 = types[1];
  
  // command
  const command = dictionary.command.replace('{area}', project.area).replace('{scenario_name}', project.scenario_name);

  // update parent state
  const updateCurrentStateHandler = useCallback((data) => {
    onUpdate(data);
  }, [onUpdate]);

  // page validation
  useEffect(() => {
    let valid;
    if (readOnly === true && current < ord) {
      valid = false; // uczen na ekranie grupowym czeka na zatwierdzenie nauczyciela
    } else if (current >= ord) {
      valid = true; // skip - krok juz zapisany
    } else {
      const selectedType1 = (state[type1]) ? state[type1].filter((obj) => obj[compute_attr] === 1).length : 0;
      const selectedType2 = (state[type2]) ? state[type2].filter((obj) => obj[compute_attr] === 1).length : 0;
      valid = (readOnly || (selectedType1 > 0 && selectedType2 > 0 && selectedType1 + selectedType2 < 11));
    }
    onValid(valid);
  });

  // page help modal
  const  help = () => {
    onHelp({ html: dictionary.help });
  };
  
  return (<main className="main-section flex w-100 items-center justify-center">
    <section className="step wide w-100 w-80-l ph3 ph0-l">
      <div className="command w-80-l flex center mb5">
        <div className="f3" dangerouslySetInnerHTML={{ __html: command }}></div>
        <div className="pl4">
          <button className="icon-btn btn-primary btn-m" onClick={help}>
            <span className="material-icons">question_mark</span>
          </button>
        </div>
      </div>
      
      <div className="flex flex-wrap nh3">

        <div className="w-100 w-50-l pr1-l mb3 mb0-l">
          <FactorsCheckboxGroup onUpdate={updateCurrentStateHandler} title={dictionary[type1]} tip={dictionary.indications} type={type1} factors={factors[type1]} state={state[type1] ?? []} compute_attr={compute_attr} readOnly={readOnly} compute_function="add_symbols" />
        </div>

        <div className="w-100 w-50-l pl1-l">
          <FactorsCheckboxGroup onUpdate={updateCurrentStateHandler} title={dictionary[type2]} tip={dictionary.indications} type={type2} factors={factors[type2]} state={state[type2] ?? []} compute_attr={compute_attr} readOnly={readOnly} compute_function="add_symbols" />
        </div>
      </div>
    </section>      
  </main>);
}

export default ChooseDoubleGroup;
