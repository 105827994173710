import React, { useState, useCallback, useEffect } from "react";
import useHttp from "../hooks/use-http";

const PageContext = React.createContext({

});

export const PageContextProvider = (props) => {
  const [idUser, setIdUser] = useState('');
  const [lang, setLang] = useState('pl');
  const { sendRequest } = useHttp();
  const [unfold, setUnfold] = useState(false);

  // componentDidMount()

  const syncFrequency = 6000;

  const setLangHandler = useCallback((lang) => {
    if (lang) {
      localStorage.setItem('lang', lang);
      setLang(lang);
      window.location.reload();
    }
  }, []);

  const setIdUserHandler = useCallback((id_user) => {
    if (id_user) {
      localStorage.setItem('id_user', id_user);
      setIdUser(id_user);
    }
  }, []);

  // unfold lists
  const setUnfoldHandler = useCallback((unfold) => {
      localStorage.setItem('unfold', (unfold) ? 1 : 0);
      setUnfold(unfold);
  }, []);

  useEffect(() => {
    // restore lang
    const storedLang = localStorage.getItem('lang');
    if (storedLang) {
      setLang(storedLang);
    } else {
      setLangHandler('pl');
    } 
    
    // restore id_user from local storage
    const storedIdUser = localStorage.getItem('id_user');
    if (storedIdUser) {
      setIdUser(parseInt(storedIdUser));
    } else {
      // Math.floor(Math.random() * 1000000)
      
      // create id_user by api
      sendRequest({
        url: '/create-user'
      }, (data) => {
        if (data.id_user) {
          setIdUserHandler(parseInt(data.id_user));
        }
      });
    }

    // restore unfold
    const storedUnfold = localStorage.getItem('unfold');
    if (storedUnfold == 1) {
      setUnfold(true);
    } 

    console.log('Restore user & lang');
  }, []);
  
  const helpHandler = useCallback((params) => {
    const { html, icon, imageUrl, imageWidth, imageHeight, width } = params;
    
    if (imageUrl) {
      window.Swal.fire({
        title: '',
        width: width,
        imageUrl: imageUrl,
        imageWidth: imageWidth,
        imageHeight: imageHeight,
        confirmButtonText: 'Ok'
      })
    } else {
      window.Swal.fire({
        title: '',
        html: html,
        icon: icon ?? 'question',
        confirmButtonText: 'Ok'
      })
    }
  }, []);

  return (
    <PageContext.Provider
      value={{
        idUser,
        onSetIdUser: setIdUserHandler,
        lang,
        onSetLang: setLangHandler,
        onHelp: helpHandler,
        syncFrequency,
        unfold,
        onSetUnfold: setUnfoldHandler
      }}
    >
      {props.children}
    </PageContext.Provider>
  );
}

export default PageContext;